/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourHeader from 'src/components/pages/Tour/TourComponents/TourHeader'
import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import { useAuth } from 'src/components/providers/AuthProvider'

const SetupTour = () => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { currentAccount, currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [open, setOpen] = useState(false)

  const closeTour = () => {
    setOpen(false)
    if (currentOrganisation) {
      void updateCurrentOrganisation({
        ...currentOrganisation,
        onboarding: {
          ...currentOrganisation.onboarding,
          setup: false,
        },
      })
    }
  }

  useEffect(() => {
    if (currentOrganisation?.onboarding.setup) {
      setOpen(currentOrganisation.onboarding.setup)
    }
  }, [currentOrganisation?.onboarding.setup])

  const myRef = useRef(document.getElementById('projects'))

  useEffect(() => {
    const element = document.getElementById('projects')
    if (element) myRef.current = element

    if (myRef.current) {
      myRef.current.addEventListener('click', closeTour)
    }
    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener('click', closeTour)
      }
    }
  }, [myRef.current, closeTour])

  const steps: ReactourStep[] = [
    {
      content: ({ goTo, step }: ReactourStepContentArgs) =>
        <TourHeader
          description='We built Releese to do one thing: help artists gain more streams, more fans, and more royalties.
          Releese can be used to track your audience, distribute your music, collect your publishing, and accumulate
          recurring pre-saves.'
          goTo={goTo}
          step={step}
          title={`Welcome to Releese, ${currentAccount.firstName}`}
        />
      ,
      position: 'right',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: theme.palette.background.default,
        boxShadow: 'rgba(1, 1, 1, 0.7) 0px 0.5em 3em',
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='All your Pre-saves are automatically onboarded to Subscriptions, growing your release day streams
          more every time you drop new music.'
          final={false}
          finalAction={() => closeTour()}
          finalText='Get started'
          goTo={goTo}
          step={step}
          title='Gain new fans'
        />
      ,
      position: mobile ? 'bottom' : 'left',
      selector: '#subscribers',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Keep track of your followers, streams, playlist placements, and short videos featuring your
          music.'
          final={false}
          finalAction={() => closeTour()}
          finalText='Get started'
          goTo={goTo}
          step={step}
          title='Track your audience'
        />
      ,
      position: mobile ? 'top' : 'right',
      selector: '#analytics',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='Plan and distribute your music, share your files, split your royalties, and create
          Pre-save campaigns from a single place. You won&apos;t believe how much time you&apos;ll save and how
          organized you will be.'
          final
          finalAction={() => closeTour()}
          finalText='Get started'
          goTo={goTo}
          step={step}
          title='Keep everything organized'
        />
      ,
      position: mobile ? 'top' : 'right',
      selector: '#releases',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask
      disableFocusLock
      disableKeyboardNavigation
      isOpen={open}
      onRequestClose={() => closeTour()}
      rounded={16}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default SetupTour
