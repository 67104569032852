/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import { LoadingButton } from '@mui/lab'
import { alpha, Button, IconButton, Paper, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate } from 'react-router'

import { createProject, firstProject } from 'src/api/projects'
import { FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import ImportReleasesModal from 'src/components/pages/Projects/EditProject/ImportReleasesModal'
import useDropzoneFullscreen from 'src/components/pages/Projects/FullscreenDropzone'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { ArtistObject } from 'src/models/Distribution'
import { NewProject } from 'src/models/Project'
import SPACING from 'src/styles/spacing'

type Props = {
  readonly close: () => void
  readonly date?: Date
}

const NewProjectModalComponent = (props: Props) => {
  const history = useNavigate()
  const themeItem = useTheme()
  const { currentOrganisation, updateCurrentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [importContent, setImport] = useState(false)

  const [artwork, setArtwork] = useState<File | undefined>()
  const [artworkPreview, setArtworkPreview] = useState<string | null>('')
  const [projectTitle, setProjectTitle] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const isDragActive = useDropzoneFullscreen()

  const [selectedPrimaryArtists, setSelectedPrimaryArtists] = useState<ArtistObject[]>([])

  const { getRootProps: getRootArtworkProps } = useDropzone({
    accept: ['.jpg', '.png', '.jpeg', '.PNG', '.JPG', '.JPEG', '.webp', '.WEBP'],
  })

  useEffect(() => {
    if (artwork) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setArtworkPreview(reader.result as string)
      }
      reader.readAsDataURL(artwork)
    } else {
      setArtworkPreview(null)
    }
  }, [artwork])

  const handleArtworkUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('image')) {
      setArtwork(file)
    } else {
      setArtwork(undefined)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)

    if (projectTitle === '') {
      setLoading(false)
      setError('Release title cannot be empty')
    } else if (currentOrganisation) {
      if (!currentOrganisation.firstProject) {
        setLoading(true)
        await updateCurrentOrganisation({ ...currentOrganisation, firstProject: true })
          .then(() => setLoading(true))
          .then(refreshCurrentOrganisation)
          .finally(() => setLoading(true))
        await firstProject()
      }

      await createProject(new NewProject({
        title: projectTitle,
        organisation: currentOrganisation,
        primaryArtists: selectedPrimaryArtists,
        projectArtwork: artwork,
        releaseDate: props.date
          ? props.date
          : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getUTCDate()),
        recordLabel: currentOrganisation.name ?? '',
        cLine: currentOrganisation.name ?? '',
        pLine: currentOrganisation.name ?? '',
        format: 'Master',
      }))
        .then(projectId => {
          setLoading(false)
          history(`/project/${projectId}`)
          props.close()
        })
        .catch(() => {
          setError('Your image upload failed, it might contain explicit content')
          setLoading(false)
        })
    }
  }

  const handlePrimaryChange = (values: ArtistObject[] | undefined) => {
    setSelectedPrimaryArtists(values ?? [])
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (event.dataTransfer.files.length > 0) {
      setArtwork(event.dataTransfer.files[0])
      event.dataTransfer.clearData()
    }
  }

  const toFindDuplicates = (array: ArtistObject[]) =>
    array.filter((item, index) => array.findIndex(object => object.name === item.name) !== index)

  const errorDuplicatePrimaryArtists = toFindDuplicates(selectedPrimaryArtists)

  return (
    <>
      <ImportReleasesModal
        close={() => setImport(false)}
        open={importContent}
      />
      <Stack
        {...getRootArtworkProps()}
        onDrop={handleDrop}
        sx={{
          border: theme => `dashed ${theme.palette.primary.main} 4px`,
          backgroundColor: theme => alpha(theme.palette.background.paper, 0.9),
          position: 'absolute',
          visibility: isDragActive ? 'visible' : 'hidden',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
          animation: 'blinker 2s linear infinite',
          '@keyframes blinker': {
            '50%': {
              opacity: 0.9,
            },
          },
        }}
      >
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            left: 0,
            textAlign: 'center',
            color: 'grey',
            fontSize: 36,
          }}
        >
          <Typography
            color={themeItem.palette.text.label}
          >
            Drop artwork file here
          </Typography>
        </Stack>
      </Stack>
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Typography variant='h3'>
            Create a Release
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Stack width={1}>
          <form
            autoComplete='off'
            onSubmit={event => {
              event.preventDefault()
              void handleSubmit()
            }}
          >
            <Stack alignItems='center' justifyContent='center' width={1}>
              <Stack marginBottom={4} marginTop={4}>
                <FileUploadButtonBase id='upload-project-artwork' onChange={handleArtworkUpload}>
                  <Paper
                    elevation={0}
                    sx={{
                      width: 256,
                      height: 256,
                      aspectRatio: '1/1',
                      marginX: 'auto',
                      border: theme => {
                        if (!artworkPreview) return `1px dashed ${theme.palette.primary.main}`
                      },
                      backgroundColor: 'transparent',
                      '&:hover': {
                        opacity: 0.5,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                      },
                    }}
                  >
                    {artworkPreview ? <img
                      alt='preview'
                      height='100%'
                      src={artworkPreview}
                      style={{ objectFit: 'cover', borderRadius: SPACING.BORDER_RADIUS }}
                      width='100%'
                    />
                      : <Stack
                        alignItems='center'
                        height={1}
                        justifyContent='center'
                        padding={2}
                        textAlign='center'
                      >
                        <ImageRoundedIcon
                          color='primary'
                          sx={{
                            width: 'inherit',
                            height: '6rem',
                            padding: 2,
                            border: theme => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: '50%',
                            background: theme => alpha(theme.palette.primary.light, 0.2),
                          }}
                        />
                        <Typography
                          sx={{ marginTop: 1 }}
                          variant='h6'
                        >
                          Pick your artwork
                        </Typography>
                        <Typography
                          color='inputText'
                          variant='inputText'
                        >
                          Drag and drop your image file or
                          {' '}
                          <span>
                            <Button
                              color='secondary'
                              sx={{
                                height: 0,
                                marginBottom: 0.1,
                                marginX: 0,
                                padding: 0,
                                fontSize: 'inherit',
                              }}
                              variant='text'
                            >
                              browse files
                            </Button>
                          </span>
                          {' '}
                          to add your artwork
                        </Typography>
                      </Stack>}
                  </Paper>
                </FileUploadButtonBase>
              </Stack>
            </Stack>
            <TextField
              autoComplete='new-password'
              error={!!error}
              fullWidth
              helperText={error}
              label='Title'
              onChange={event_ => setProjectTitle(event_.target.value)}
              placeholder='My first release'
              value={projectTitle}
            />
            <Stack marginTop={2} width={1}>
              <SearchArtistComponent
                error={errorDuplicatePrimaryArtists.length > 0}
                label='Artists'
                placeholder='Search Artists'
                selectedArtists={selectedPrimaryArtists}
                setSelectedArtists={handlePrimaryChange}
              />
            </Stack>
            <Stack marginTop={2} width={1}>
              <Typography
                color='text.label'
                variant='body2'
              >
                Other users in your team will be able to see this release once it is created.
              </Typography>
            </Stack>

            <Stack direction='row' justifyContent='space-between' marginTop={4} spacing={1} width={1}>
              <Stack direction='row' spacing={1}>
                <Button
                  endIcon={<CloudDownloadRoundedIcon />}
                  onClick={() => setImport(true)}
                  rounded
                  sx={{
                    width: 'fit-content',
                    flexShrink: 0,
                  }}
                  variant='outlined'
                >
                  Import
                </Button>
              </Stack>
              <Stack direction='row' spacing={1}>
                <Button
                  onClick={() => props.close()}
                  sx={{
                    width: 'fit-content',
                    flexShrink: 0,
                  }}
                  variant='text'
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={projectTitle === '' || errorDuplicatePrimaryArtists.length > 0}
                  endIcon={<AddRoundedIcon />}
                  loading={loading}
                  sx={{
                    width: 'fit-content',
                    flexShrink: 0,
                  }}
                  type='submit'
                  variant='contained'
                >
                  Create Release
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  )
}

export default NewProjectModalComponent
