import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Card, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { getProjects } from 'src/api/projects'
import CreateReleaseItem from 'src/components/pages/Dashboard/Releases/ReleasesComponents/CreateReleaseItem'
import ReleaseItem from 'src/components/pages/Dashboard/Releases/ReleasesComponents/ReleaseItem'
import ReleaseItemSkeleton from 'src/components/pages/Dashboard/Releases/ReleasesComponents/ReleaseItemSkeleton'
import { useAuth } from 'src/components/providers/AuthProvider'
import { NavLink } from 'src/components/SafeRouterLink'
import type Project from 'src/models/Project'

const ReleasesCard = () => {
  const { currentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Project[]>([])

  useEffect(() => {
    setLoading(true)
    getProjects().then(result => {
      setData(result.slice(0, 10))
    }).finally(() => setLoading(false))
  }, [currentOrganisation?.id])

  return (
    <Grid id='releases' item lg={12} md={12} sm={12} xl={12} xs={12}>
      <Card sx={{ width: 1 }}>
        <Stack width={1}>
          <Stack alignItems='center' direction='row' justifyContent='space-between' padding={2}>
            <Typography lineHeight={1.2} variant='h2'>
              Releases
            </Typography>
            <IconButton component={NavLink} to='/projects/projects'>
              <ArrowForwardIosRoundedIcon />
            </IconButton>
          </Stack>
          {!loading
            ? <Stack overflow='auto' paddingBottom={2} paddingX={2} width={1}>
              <Stack direction='row' spacing={2} width='fit-content'>
                <CreateReleaseItem />
                {data.map(project =>
                  <ReleaseItem key={`project-${project.id}`} project={project} />)}
              </Stack>
            </Stack>
            : <Stack overflow='auto' paddingBottom={2} paddingX={2} width={1}>
              <Stack direction='row' spacing={2} width='fit-content'>
                <CreateReleaseItem />
                <ReleaseItemSkeleton />
                <ReleaseItemSkeleton />
                <ReleaseItemSkeleton />
                <ReleaseItemSkeleton />
                <ReleaseItemSkeleton />
              </Stack>
            </Stack>}
        </Stack>
      </Card>
    </Grid>
  )
}

export default ReleasesCard
