/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable no-restricted-imports */
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import StoreRoundedIcon from '@mui/icons-material/StoreRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { AppBar, Button, Dialog, Divider, IconButton, MenuItem, Select, Slide, Stack, Tab, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { updateMetadata } from 'src/api/distribution'
import { getRecordingsByProjectId } from 'src/api/recordings'
import { isrcValidation } from 'src/components/pages/Projects/EditProject/EditProjectTabs/RecordingsTab/RecordingTabs/PublishingTab/RecordingPublishingTab'
import CompleteUpdate from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/DistributeComplete'
import RecordingsUpdate from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/Recordings'
import StoresUpdate from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/StoresDistribute'
import SubmitUpdate from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/Submit'
import TerritoriesUpdate from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/Territories'
import UpdateDistributionTermsModal from 'src/components/pages/Projects/UpdateModal/DistributionUpdateAgreeTermsModal'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { useAuth } from 'src/components/providers/AuthProvider'
import { continentsData } from 'src/data/distributionCountries'
import { defaultSelectAllPlatforms } from 'src/data/storesData'
import { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly close: () => void
  readonly open: boolean
  readonly project: Project
  readonly distribution: Distribution
}

type ErrorMessageServer = {
  response: {
    data: {
      message: string
    }
  }
}

const UpdateReleaseModal = (props: Props) => {
  const { currentFirebaseUser } = useAuth()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [termsModal, setTermsModal] = useState(false)
  const [_, setLoading] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)
  const [stores, setStores] = useState(defaultSelectAllPlatforms)
  const [recordings, setRecordings] = useState<Recording[]>([])
  const [error, setError] = useState('')
  const [beatportGenre, setBeatportGenre] = useState('')
  const [territories, setTerritories] = useState(continentsData.flatMap(({ countries }) => countries))
  const [projectFields, setProject] = useState<Project | undefined>(props.project)
  const [artwork, setArtwork] = useState<File>()
  const [artworkPreview, setArtworkPreview] = useState<string | null | undefined>(props.project.artworkPreviewUrl)
  const [moderationMessage, setModerationMessage] = useState('')
  const [attachment, setAttachment] = useState<File | undefined>()

  const handleArtworkUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file === undefined) return

    if (file.type.startsWith('image')) {
      setArtwork(file)
    } else {
      setArtwork(undefined)
    }
  }

  useEffect(() => {
    if (artwork) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setArtworkPreview(reader.result as string)
      }
      reader.readAsDataURL(artwork)
    }
  }, [artwork])

  const checkTitle = recordings?.map(recording => recording.title && recording.title.length > 0)
    .every(element => element === true)

  const checkTitleRemixVersionErrors = recordings?.map(recording =>
    !recording.title.toLowerCase().includes('remix') &&
    !recording.subTitle.toLowerCase().includes('remix'))
    .every(element => element)

  const checkPrimaryArtists = recordings?.map(recording => recording.primaryArtists.length > 0)
    .every(element => element)
  const checkCoverData = recordings?.map(recording => recording.trackType === 'Cover'
  // eslint-disable-next-line no-unneeded-ternary, @typescript-eslint/prefer-optional-chain
    ? recording.coverItem && recording.coverItem.name && recording.coverItem.name.length > 0
      ? true
      : false
    : true)
    .every(element => element)
  const checkRemixData = recordings?.map(recording => recording.trackType === 'Remix'
  // eslint-disable-next-line no-unneeded-ternary, @typescript-eslint/prefer-optional-chain
    ? recording.remixArtists.length > 0
      ? true
      : false
    : true)
    .every(element => element)
  const checkUniqueArtists = recordings?.map(recording =>
    !recording.primaryArtists.map(item => item.name).some(artist =>
      recording.featuredArtists.map(item => item.name).includes(artist)) &&
    !recording.featuredArtists.map(item => item.name).some(artist =>
      recording.remixArtists.map(item => item.name).includes(artist)))
    .every(element => element)
  const checkType = recordings?.map(recording => recording.trackType && recording.trackType.length > 0)
    .every(element => element)
  const checkWrittenBy = recordings?.map(recording =>
    recording.writtenBy.map(writer => writer.split(' ').length > 1).every(element => element) &&
    recording.writtenBy.length > 0 &&
    recording.writtenBy.length < 15)
    .every(element => element)
  const checkComposedBy = recordings?.map(recording =>
    recording.composedBy.map(composer => composer.split(' ').length > 1).every(element => element) &&
    recording.composedBy.length > 0 &&
    recording.composedBy.length < 15)
    .every(element => element)
  const checkProducedBy = recordings?.map(recording =>
    recording.producedBy.length > 0 &&
    recording.producedBy.length < 15)
    .every(element => element)
  const checkLanguage = recordings?.map(recording => recording.language && recording.language.length > 0)
    .every(element => element === true)
  const checkMasterOwnership = recordings?.map(recording => Number(recording.masterRightsHolders.map(holder =>
    Number(holder.ownership.toFixed(2))).reduce((a, b) => a + b, 0).toFixed(2)) === 100)
    .every(element => element)
  const checkMasterLocked = recordings?.map(recording => recording.masterRightsHolders.map(holder =>
    holder.status === 'Locked').every(element => element))
    .every(element => element)
  const checkISRC = !!recordings &&
    recordings.map(recording => recording.ISRC.length === 0 ||
      (recording.ISRC.length > 0 &&
        isrcValidation.test(recording.ISRC)))
      .every(element => element) &&
    new Set(recordings.map(item => item.ISRC).filter(item => item.length > 0)).size ===
      recordings.map(item => item.ISRC).filter(item => item.length > 0).length

  const startYearRegex = /^[12]\d{3}/
  const endYearRegex = /[12]\d{3}$/
  const endsWithNumber = /\d$/
  const startsWithNumber = /^\d/
  const matchVolume = /Vol\. \d+/

  const errorTitlesRecordings =
    recordings?.map(item => !endsWithNumber.test(item.title.trim()) ||
    endYearRegex.test(item.title.trim()) ||
    matchVolume.test(item.title.trim()))
      .every(element => element) ?? false

  const recordingTitlesStartWithNumbers = !!recordings
  recordings.map(item => !startsWithNumber.test(item.title.trim()) || startYearRegex.test(item.title.trim()))
    .every(element => element)

  const recordingSubtitleParentheses = !!recordings &&
    recordings.map(item =>
      !item.subTitle.includes('(') &&
      !item.subTitle.includes(')'))
      .every(element => element)

  const allTitles = recordings?.map(item => generateTitleRecording(undefined, item)) ?? []
  const duplicateTitleError = new Set(allTitles).size === allTitles.length

  const checkRecordings = checkTitle && checkPrimaryArtists && checkType &&
    checkWrittenBy && checkComposedBy && checkProducedBy && checkLanguage &&
    checkMasterOwnership && checkISRC && checkMasterLocked && checkCoverData && checkRemixData && checkUniqueArtists &&
    duplicateTitleError && checkTitleRemixVersionErrors

  const errorHandling = !projectFields?.title ||
    territories.length === 0 || stores.length === 0 || recordings?.length === 0 || !projectFields.genre ||
    !projectFields.recordLabel || !projectFields.cLine || !projectFields.pLine ||
    !projectFields.projectArtworkUrl || !props.project?.releaseDate ||
    !checkRecordings || !errorTitlesRecordings || !recordingTitlesStartWithNumbers || !recordingSubtitleParentheses

  // eslint-disable-next-line max-len
  const allRecordingChecks = !!recordings && checkRecordings && recordingSubtitleParentheses && errorTitlesRecordings && recordingTitlesStartWithNumbers && recordings?.length > 0
  const allTerritoryChecks = territories.length > 0
  const allStoresChecks = stores.length > 0
  // eslint-disable-next-line max-len
  const allSubmitChecks = projectFields && projectFields.cLine.length > 0 && projectFields.pLine.length > 0 && projectFields.genre.length > 0 && projectFields.title.length > 0 && projectFields.projectArtworkUrl.length > 0 && !!projectFields.releaseDate && projectFields.recordLabel.length > 0

  useEffect(() => {
    if (props.project) {
      setLoading(true)
      getRecordingsByProjectId(props.project.id)
        .then(recordingItems => {
          const newArray = recordingItems.sort((a, b) =>
            props.project.recordings.indexOf(a.id) - props.project.recordings.indexOf(b.id))
          setRecordings(newArray)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [])

  const distribute = async () => {
    if (projectFields && recordings && currentFirebaseUser.emailVerified) {
      setLoadingSubmit(true)
      const distributionItemSubmit = new Distribution({
        title: projectFields.title,
        primaryArtists: projectFields.primaryArtists,
        featuredArtists: projectFields.featuredArtists,
        genre: projectFields.genre,
        subGenres: projectFields.subGenres,
        labelName: projectFields.recordLabel,
        releaseDate: projectFields.releaseDate,
        cLine: projectFields.cLine,
        pLine: projectFields.pLine,
        catalogNumber: projectFields.catalogNumber,
        artworkUrl: projectFields.projectArtworkUrl,
        UPC: props.project.UPC,
        beatportGenre,
        territories,
        platforms: stores,
        recordings,
        projectId: projectFields.id,
        status: 'Under review',
        artwork,
      })
      await updateMetadata(distributionItemSubmit, moderationMessage, attachment)
        .then(() => {
          setCurrentTab(currentTab + 1)
          setLoadingSubmit(false)
        })
        .catch((error_: ErrorMessageServer) => {
          if (error_.response?.data?.message && error_.response?.data?.message?.length > 0) {
            setError(error_.response.data.message)
            setLoadingSubmit(false)
          } else {
            setCurrentTab(currentTab + 1)
            setLoadingSubmit(false)
          }
        })
    }
  }

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen
        onClose={props.close}
        open={props.open}
        sx={{
          '& .MuiPaper-root': {
            transform: 'none!important',
          },
          '.MuiDialog-paper': {
            height: '100%',
          },
        }}
      >
        <Slide direction='up' in={props.open}>
          <Stack
            height={1}
            overflow='auto'
            sx={{
              background: theme => theme.palette.background.input,
            }}
          >
            <TabContext value={currentTab.toString()}>

              <Stack
                paddingX={2}
                top={2}
                width={1}
              >
                <AppBar
                  position='fixed'
                  sx={{
                    background: theme => theme.palette.background.default,
                    borderRadius: 2,
                    zIndex: '99999!important',
                    top: 16,
                    marginLeft: 2,
                    marginRight: 2,
                    paddingX: 2,
                    width: 'calc(100% - 32px)',
                  }}
                  variant='outlined'
                >
                  <Toolbar
                    sx={{
                      justifyContent: 'space-between',
                      paddingX: '8px!important',
                    }}
                  >
                    <Stack
                      alignItems='center'
                      direction='row'
                      spacing={2}
                      width='calc(100% - 40px)'
                    >
                      {!matches &&
                      <Typography variant='h3'>
                        Update release
                      </Typography>}

                      {currentTab !== 7 && matches &&
                      <Select
                        onChange={event => setCurrentTab(Number(event.target.value))}
                        value={currentTab.toString()}
                      >
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='1'>
                          Recordings
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='2'>
                          Territories
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='3'>
                          Stores
                        </MenuItem>
                        <MenuItem disabled={loadingSubmit || error.length > 0} value='7'>
                          Submit
                        </MenuItem>
                      </Select>}

                      {currentTab !== 8 && !matches &&
                      <TabList
                        aria-label='project view'
                        onChange={(event, value: string) => setCurrentTab(Number(value))}
                        sx={{
                          minHeight: '32px',
                          '.MuiTab-root': {
                            borderRadius: 16,
                          },
                          '.MuiTabs-indicator': {
                            visibility: 'hidden',
                          },
                        }}
                        variant='scrollable'
                      >
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || currentTab === 5}
                          icon={allRecordingChecks
                            ? <CheckCircleRoundedIcon color='success' />
                            : <WarningRoundedIcon color='error' />}
                          iconPosition='end'
                          label={<>
                            <MusicNoteRoundedIcon sx={{ marginRight: 1 }} />
                            Recordings
                          </>}
                          sx={{ minHeight: 32, padding: '0px 12px!important' }}
                          value='1'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || currentTab === 5}
                          icon={allTerritoryChecks
                            ? <CheckCircleRoundedIcon color='success' />
                            : <WarningRoundedIcon color='error' />}
                          iconPosition='end'
                          label={<>
                            <PublicRoundedIcon sx={{ marginRight: 1 }} />
                            Territories
                          </>}
                          sx={{ minHeight: 32, padding: '0px 12px!important' }}
                          value='2'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || currentTab === 5}
                          icon={allStoresChecks
                            ? <CheckCircleRoundedIcon color='success' />
                            : <WarningRoundedIcon color='error' />}
                          iconPosition='end'
                          label={<>
                            <StoreRoundedIcon sx={{ marginRight: 1 }} />
                            Stores
                          </>}
                          sx={{ minHeight: 32, padding: '0px 12px!important' }}
                          value='3'
                        />
                        <Tab
                          disabled={loadingSubmit || error.length > 0 || currentTab === 5}
                          icon={allSubmitChecks
                            ? <CheckCircleRoundedIcon color='success' />
                            : <WarningRoundedIcon color='error' />}
                          iconPosition='end'
                          label={<>
                            <CheckCircleOutlineRoundedIcon sx={{ marginRight: 1 }} />
                            Submit
                          </>}
                          sx={{ minHeight: 32, padding: '0px 12px!important' }}
                          value='4'
                        />
                      </TabList>}
                    </Stack>
                    {currentTab !== 5 &&
                    <IconButton
                      aria-label='close'
                      disabled={loadingSubmit || error.length > 0}
                      onClick={props.close}
                    >
                      <CloseRoundedIcon />
                    </IconButton>}
                  </Toolbar>
                </AppBar>
              </Stack>
              <Stack
                alignItems='center'
                height={1}
                overflow='auto'
                paddingTop={11}
                position='relative'
                width={1}
              >
                <Stack
                  maxWidth='lg'
                  paddingBottom={8}
                  paddingX={1}
                  width={1}
                >
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='1'>
                    {recordings &&
                    <RecordingsUpdate
                      project={props.project}
                      recordings={recordings}
                      setRecordings={setRecordings}
                    />}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='2'>
                    <TerritoriesUpdate
                      setStores={setStores}
                      setTerritories={setTerritories}
                      stores={stores}
                      territories={territories}
                    />
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='3'>
                    <StoresUpdate
                      beatportGenre={beatportGenre}
                      setBeatportGenre={setBeatportGenre}
                      setStores={setStores}
                      stores={stores}
                      territories={territories}
                    />
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='4'>
                    {recordings &&
                    <SubmitUpdate
                      artworkPreview={artworkPreview}
                      attachment={attachment}
                      distribution={props.distribution}
                      errorHandling={errorHandling}
                      errorMessage={error}
                      handleArtworkUpload={handleArtworkUpload}
                      loading={loadingSubmit}
                      modMessage={moderationMessage}
                      project={projectFields}
                      recordings={recordings}
                      setAttachment={setAttachment}
                      setModMessage={setModerationMessage}
                      setProject={setProject}
                      stores={stores}
                      territories={territories}
                    />}
                  </TabPanel>
                  <TabPanel sx={{ width: 1, height: 1, paddingX: 1 }} value='5'>
                    <CompleteUpdate
                      close={props.close}
                      project={props.project}
                    />
                  </TabPanel>
                </Stack>
              </Stack>
            </TabContext>
            {currentTab !== 5 &&
            <AppBar
              position='fixed'
              sx={{
                top: 'auto',
                bottom: 0,
                background: theme => theme.palette.background.default,
              }}
            >
              <Divider sx={{ width: 1 }} />
              <Toolbar
                sx={{
                  justifyContent: 'flex-end',
                  paddingX: 1,
                  paddingTop: 1,
                  paddingBottom: 1,
                }}
              >
                <Stack
                  direction={matches ? 'column' : 'row'}
                  spacing={1}
                  width={1}
                >
                  <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                    <Button
                      disabled={currentTab === 1 || loadingSubmit || error.length > 0 || currentTab === 5}
                      onClick={() => {
                        setCurrentTab(currentTab - 1)
                      }}
                      startIcon={<ArrowBackIosNewRoundedIcon />}
                      variant='subtle'
                    >
                      Previous
                    </Button>

                    <Button
                      disabled={currentTab === 4 || loadingSubmit || error.length > 0 || currentTab === 5}
                      endIcon={<ArrowForwardIosRoundedIcon />}
                      onClick={() => {
                        setCurrentTab(currentTab + 1)
                      }}
                    >
                      Continue
                    </Button>
                    <LoadingButton
                      disabled={errorHandling ||
                      currentTab !== 4 ||
                      error.length > 0 ||
                      !currentFirebaseUser.emailVerified ||
                      (props.distribution.status === 'Changes requested' && moderationMessage.length === 0)}
                      endIcon={<PublishRoundedIcon />}
                      loading={loadingSubmit}
                      onClick={() => setTermsModal(true)}
                      variant='contained'
                    >
                      Update release
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Toolbar>
            </AppBar>}
          </Stack>
        </Slide>
      </Dialog>
      <UpdateDistributionTermsModal
        agreeTerms={agreeTerms}
        close={() => setTermsModal(false)}
        distribute={distribute}
        loadingSubmit={loadingSubmit}
        open={termsModal}
        setAgreeTerms={setAgreeTerms}
      />
    </>
  )
}

export default UpdateReleaseModal
