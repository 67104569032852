import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded'
import { LoadingButton } from '@mui/lab'
import { Card, CardActionArea, Checkbox, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'

import { onboardingEvent } from 'src/api/organisation'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly isArtist: boolean
}

const ClientPersona = (props: Props) => {
  const navigate = useNavigate()
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()

  const [loading, setLoading] = useState(false)
  const [distribute, setDistribute] = useState(false)
  const [gainFans, setGainFans] = useState(false)
  const [trackAudience, setTrackAudience] = useState(false)
  const [organize, setOrganize] = useState(false)
  const [splits, setSplits] = useState(false)

  const handleSubmit = async () => {
    void onboardingEvent('Complete Sign up')
    setLoading(true)
    await updateCurrentOrganisation({
      ...currentOrganisation,
      persona_features: {
        distribution: distribute,
        gainFans,
        trackAudience,
        organize,
        splits,
      },
    })
      .then(() => setLoading(false))
      .finally(() => navigate({ pathname: '/', search: document.location.search }))
  }

  return (
    <Stack
      justifyContent='center'
      maxWidth='md'
      sx={{
        border: 'none',
        width: 1,
      }}
    >
      <Stack
        alignItems='flex-start'
        height={1}
        spacing={4}
        width={1}
      >
        <Stack
          marginX='auto'
          width={1}
        >
          <Stack alignItems='flex-start' direction='column' width={1}>
            <Stack alignItems='flex-start' direction='column' spacing={2} width={1}>
              <Stack direction='column'>
                <Typography lineHeight={1.2} textAlign='left' variant='h2'>
                  Create your perfect dashboard
                </Typography>
                <Typography color='textSecondary' textAlign='left' variant='body1' >
                  I joined Releese to... (Be ambitious, select more than one!)
                </Typography>
              </Stack>
              <Stack
                direction='column'
                spacing={1}
                width={1}
              >
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '64px',
                  }}
                >
                  <CardActionArea onClick={() => setDistribute(old => !old)}>
                    <Stack alignItems='center' direction='row' padding={1} paddingRight={2} spacing={1} width={1}>
                      <Checkbox
                        checked={distribute}
                        checkedIcon={<CheckCircleRoundedIcon />}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='large'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '32px',
                          },
                          flexShrink: 0,
                        }}
                      />
                      <Typography sx={{ width: 1 }} variant='body1'>
                        Distribute music, split royalties, and maximize my earnings
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '64px',
                  }}
                >
                  <CardActionArea onClick={() => setGainFans(old => !old)}>
                    <Stack alignItems='center' direction='row' padding={1} paddingRight={2} spacing={1} width={1}>
                      <Checkbox
                        checked={gainFans}
                        checkedIcon={<CheckCircleRoundedIcon />}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='large'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '32px',
                          },
                          flexShrink: 0,
                        }}
                      />
                      <Typography sx={{ width: 1 }} variant='body1'>
                        Gain more listeners and new fans
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '64px',
                  }}
                >
                  <CardActionArea onClick={() => setTrackAudience(old => !old)}>
                    <Stack alignItems='center' direction='row' padding={1} paddingRight={2} spacing={1} width={1}>
                      <Checkbox
                        checked={trackAudience}
                        checkedIcon={<CheckCircleRoundedIcon />}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='large'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '32px',
                          },
                          flexShrink: 0,
                        }}
                      />
                      <Typography sx={{ width: 1 }} variant='body1'>
                        View top playlist placements, follower trends, and advanced analytics
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '64px',
                  }}
                >
                  <CardActionArea onClick={() => setOrganize(old => !old)}>
                    <Stack alignItems='center' direction='row' padding={1} paddingRight={2} spacing={1} width={1}>
                      <Checkbox
                        checked={organize}
                        checkedIcon={<CheckCircleRoundedIcon />}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='large'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '32px',
                          },
                        }}
                      />
                      <Typography variant='body1'>
                        Create release plans, store files, and work with collaborators
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>

                <Card
                  elevation={0}
                  sx={{
                    borderRadius: '64px',
                  }}
                >
                  <CardActionArea onClick={() => setSplits(old => !old)}>
                    <Stack alignItems='center' direction='row' padding={1} spacing={1} width={1}>
                      <Checkbox
                        checked={splits}
                        checkedIcon={<CheckCircleRoundedIcon />}
                        color='success'
                        icon={<RadioButtonUncheckedRoundedIcon />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        rounded
                        size='large'
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: '32px',
                          },
                        }}
                      />
                      <Typography variant='body1'>
                        Collect the royalty split I was invited to
                      </Typography>
                    </Stack>
                  </CardActionArea>
                </Card>
              </Stack>

              <Stack direction='row' justifyContent='flex-end' spacing={1} width={1}>
                <LoadingButton
                  disabled={!distribute && !gainFans && !trackAudience && !organize && !splits}
                  endIcon={<ChevronRightRoundedIcon />}
                  loading={loading}
                  onClick={() => void handleSubmit()}
                  variant='contained'
                >
                  Go to my
                  {props.isArtist ? ' artist ' : ' label '}
                  dashboard
                </LoadingButton>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ClientPersona
