import { Button, Card, CardActionArea, darken, Stack, Typography, useTheme } from '@mui/material'
import { v4 } from 'uuid'

type Props = {
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly essential: number
  readonly month: number
}

const features = [
  'Unlimited user logins',
  'Unlimited storage',
  'Unlimited Pre-save subscribers',
  'Export subscribers emails',
  'Keep 90% of earnings',
]

const EssentialCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        position: 'relative',
        boxShadow: 'inset 0 8px 0 #327659 !important',
        width: 1,
      }}
      variant='outlined'
    >
      <CardActionArea onClick={props.handleBilling(props.essential)} sx={{ height: 1 }}>
        <Typography
          sx={{
            position: 'absolute',
            top: '0px',
            marginX: 'auto',
            width: 'fit-content',
            left: 0,
            right: 0,
            background: '#327659',
            borderRadius: '0px 0px 9px 8px',
            paddingBottom: '8px',
            paddingTop: '4px',
            paddingX: '16px',
            color: 'white',
          }}
        >
          &#9733; Most popular
        </Typography>
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2} width={1}>
          <Stack paddingTop={2}>
            <Typography fontWeight='normal' variant='h2'>
              Essential
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? props.billingCycle * props.essential
                  : props.billingCycle * props.essential / 12 * 0.8}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed annually
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Access to all essential artist tools
          </Typography>
          <Button
            color='primary'
            fullWidth
            sx={{
              background: '#327659',
              ':hover': {
                background: darken('#327659', 0.25),
              },
            }}
          >
            Get started
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h4'>
              Everything in Starter, and
            </Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            {features.map(item =>
              <Stack alignItems='center' direction='row' key={v4()} spacing={1}>
                <Typography variant='body2'>
                  <span>
                    ✓
                  </span>
                  <span style={{ paddingLeft: 8 }}>
                    {item}
                  </span>
                </Typography>
              </Stack>)}
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default EssentialCard
