/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Typography, useTheme } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import type { StripeElementsOptions } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import type { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router'

import { onboardingEvent } from 'src/api/organisation'
import { cancelSubscription, getProductPrice, getProtatedPrice } from 'src/api/payment'
import CancelSubscriptionComponent from 'src/components/pages/Account/Billing/BillingComponents/CancelSubscription'
import CheckoutPage from 'src/components/pages/Account/Billing/CheckoutPage'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Organisation, PriceLookupKey } from 'src/models/Organisation'
import { PRICE_LOOKUP_KEYS } from 'src/models/Organisation'
import type { UpcomingInvoice } from 'src/models/Payment'

type Props = {
  readonly account: Organisation
  readonly priceLookupKey: PriceLookupKey
  readonly term: number
  readonly seats: number
  readonly setCheckout: (checkout: string) => void
  readonly neverTrial: boolean
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '')

const CheckoutFormComponent = (props: Props) => {
  const theme = useTheme()
  const { refreshCurrentOrganisation } = useAuth()
  const [errorMessage, setErrorMessage] = useState('')
  const [proratedPrice, setProratedTotal] = useState<UpcomingInvoice>()
  const [priceSelected, setPriceSelected] = useState<number>()

  const [firstName, setFirstName] = useState(props.account.firstName)
  const [lastName, setLastName] = useState(props.account.lastName)
  const [city, setCity] = useState(props.account.city)
  const [line1, setLine1] = useState(props.account.addressLine1)
  const [region, setRegion] = useState(props.account.region)
  const [postalCode, setPostalCode] = useState(props.account.postalCode)
  const [country, setCountry] = useState(props.account.country)

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const key = props.priceLookupKey as Exclude<PriceLookupKey, 'FREE'>

  useEffect(() => {
    void getProductPrice(key, props.seats)
      .then(price => price / 100)
      .then(setPriceSelected)
    void getProtatedPrice(key, props.seats, country, city, line1, postalCode, region)
      .then(price => setProratedTotal(price))
  }, [city, country, line1, postalCode, props.seats, key, region])

  useEffect(() => {
    if (props.priceLookupKey === 'FREE' && !errorMessage) {
      cancelSubscription()
        .then(() => refreshCurrentOrganisation())
        .then(() => props.setCheckout('cancelled'))
        .catch((error: AxiosError<Error>) => setErrorMessage(error.response?.data.message ?? error.message))
    }
  }, [props, props.priceLookupKey, errorMessage])

  useEffect(() => {
    void onboardingEvent('Start checkout')
  }, [])

  if (errorMessage) {
    return <Typography color='error' variant='h6'>
      Something went wrong while cancelling your subscription
      <br />
      {errorMessage}
    </Typography>
  } else if (props.priceLookupKey === 'FREE') {
    return <CancelSubscriptionComponent />
  }

  // Guard this page when no valid membership tier have been selected
  if (!PRICE_LOOKUP_KEYS.includes(props.priceLookupKey)) {
    return <Navigate to={{ pathname: '/settings/billing/plan', search: window.location.search }} />
  }

  const options = {
    mode: 'subscription',
    amount: 0,
    currency: 'usd',
    appearance: {
      theme: 'flat',
      variables: {
        colorText: theme.palette.text.primary,
        colorDanger: theme.palette.error.main,
        colorPrimary: theme.palette.primary.main,
        colorTextSecondary: theme.palette.text.secondary,
        colorBackground: theme.palette.stripeBg,
        fontSizeBase: '1rem',
        fontSizeSm: '0.825rem',
        fontSizeLg: '0.825rem',
        fontWeightNormal: '400',
        fontWeightLight: '400',
        fontWeightBold: '400',
        fontWeightMedium: '400',
        colorSuccess: theme.palette.success.main,
        // eslint-disable-next-line max-len
        fontFamily: 'Matter,sans-serif,Moderna,Helvetica Neue,Droid Sans,Fira Sans,Cantarell,Ubuntu,Inter,Oxygen,Roboto,Segoe UI,BlinkMacSystemFont,-apple-system',
      },
      rules: {
        '.Label': {
          color: theme.palette.text.label,
          fontSize: '0.75em',
        },
        '.Input': {
          fontSize: '0.825rem',
          padding: '12px',
        },
        '.Block': {
          backgroundColor: theme.palette.background.elevatedCard,
        },
      },
    },
    setup_future_usage: 'off_session',
    setupFutureUsage: 'off_session',
  } as StripeElementsOptions

  return <Stack spacing={2} width={1}>
    <Elements options={options} stripe={stripePromise}>
      <CheckoutPage
        city={city}
        country={country}
        firstName={firstName}
        lastName={lastName}
        line1={line1}
        neverTrial={props.neverTrial}
        postalCode={postalCode}
        priceSelected={priceSelected}
        proratedPrice={proratedPrice}
        region={region}
        seats={props.seats}
        selectedPrice={props.priceLookupKey}
        setCheckout={props.setCheckout}
        setCity={setCity}
        setCountry={setCountry}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setLine1={setLine1}
        setPostalCode={setPostalCode}
        setRegion={setRegion}
        term={props.term}
      />
    </Elements>
  </Stack>
}

export default CheckoutFormComponent
