import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Card, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'

import { inviteUserToOrganisation } from 'src/api/organisation'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  close: () => void
  open: boolean
}

const InviteMemberModal = (props: Props) => {
  const themeColors = useTheme()
  const fullScreen = useMediaQuery(themeColors.breakpoints.down('md'))

  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [permissionGroups, setPermissionGroup] = useState('')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [billingModal, setBillingModalOpen] = useState(false)

  const selectableRoles =
    currentOrganisation?.organisationRoles.filter(role => role.name.toLocaleLowerCase() !== 'owner')

  const exists = currentOrganisation?.seats.some(seat => seat.account.email === email)

  const seats = currentOrganisation?.seatAllocation ?? 0
  const currentSeats = currentOrganisation?.seats.length ?? 0

  const seatsLeft = seats - currentSeats

  const inviteMember = async () => {
    if (!exists) {
      setLoading(true)
      await inviteUserToOrganisation(email, permissionGroups)
        .then(() => setLoading(false))
        .then(refreshCurrentOrganisation)
      props.close()
    }
  }

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='xs'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <form
          onSubmit={event => {
            event.preventDefault()
            void inviteMember()
          }}
        >
          <Stack paddingX={3} paddingY={3} spacing={2} width={1}>
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              width='100%'
            >
              <Typography variant='h3'>
                Invite to Releese
              </Typography>
              <IconButton onClick={() => props.close()}>
                <CloseIcon color='disabled' />
              </IconButton>
            </Stack>
            {currentOrganisation?.seatAllocation !== Number.POSITIVE_INFINITY &&
            <Card
              elevation={0}
              sx={{
                background: theme => theme.palette.background.elevatedCard,
                padding: 2,
              }}
            >
              <Stack alignItems='center' spacing={2} width={1}>
                <AutoAwesomeRoundedIcon
                  color='success'
                  sx={{
                    width: 64,
                    height: 64,
                    color: '#FFA500',
                  }}
                />
                <Stack alignItems='center' width={1}>
                  <Typography
                    color={themeColors.palette.text.primary}
                    sx={{
                      width: 1,
                    }}
                    textAlign='center'
                    variant='h3'
                  >
                    {seatsLeft > 0
                      ? `You can invite ${seatsLeft} more user${seatsLeft > 1 ? 's' : ''}`
                      : 'You are out of seats'}
                  </Typography>
                  <Typography
                    color={themeColors.palette.text.secondary}
                    sx={{
                      width: 1,
                    }}
                    textAlign='center'
                    variant='body2'
                  >
                    {seatsLeft > 0
                      ? `Want to invite more than ${seatsLeft} user${seatsLeft > 1 ? 's' : ''}? This might be the right 
                  time to upgrade your plan`
                      : `Your team is growing and we want to help you scale, upgrade today to give your team the right
                    tools for the job`}
                  </Typography>
                </Stack>
                <Button
                  color='success'
                  onClick={() => setBillingModalOpen(true)}
                  sx={{
                    color: 'white!important',
                    background: '#FFA500',
                    ':hover': {
                      background: '#c47f00',
                    },
                  }}
                >
                  Upgrade now
                </Button>
              </Stack>
            </Card>}

            <Stack paddingTop={2} spacing={4} width='100%'>
              <TextField
                disabled={seatsLeft < 1}
                fullWidth
                label='Email'
                onChange={event => setEmail(event.target.value)}
                placeholder='jane_doe@company.com'
                type='email'
                value={email}
              />
              <Autocomplete
                disabled={seatsLeft < 1}
                getOptionLabel={option => option.name}
                onChange={(event, value) => setPermissionGroup(value?.id ?? '')}
                options={selectableRoles ?? []}
                renderInput={params =>
                  <TextField
                    {...params}
                    disabled={seatsLeft < 1}
                    fullWidth
                    label='Permissions'
                    placeholder='Permissions'
                    sx={{
                      marginTop: 0,
                      width: 1,
                      textTransform: 'capitalize',
                    }}
                  />}
                renderOption={(renderProps, option) =>
                  <Box component='li' {...renderProps}>
                    {option.name}
                  </Box>}
                sx={{
                  width: 1,
                  marginTop: 0,
                }}
                value={selectableRoles?.find(item => item.id === permissionGroups)}
              />
            </Stack>
            {exists &&
            <Typography color='error' textAlign='center' variant='body2'>
              This user is already a member of this organisation
            </Typography>}
            <Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
              <Button onClick={() => props.close()} variant='text'>
                Cancel
              </Button>
              <LoadingButton
                disabled={(email.length === 0 || permissionGroups.length === 0 || seatsLeft < 1 ||  exists)}
                loading={loading}
                type='submit'
                variant='contained'
              >
                Add member
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Dialog>

      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
    </>
  )
}

export default InviteMemberModal
