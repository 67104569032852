/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable max-len */
import type { CancelTokenSource } from 'axios'
import axios from 'axios'

import type { OrganisationDto } from 'src/models/Organisation'
import { Organisation } from 'src/models/Organisation'
import type { Collaborator, NewProject, ProjectDto, ProjectUpdate } from 'src/models/Project'
import Project from 'src/models/Project'
import type { Recoupment } from 'src/models/Recording'
import { toFormData } from 'src/utils/objectUtils'

const PATH = '/projects' as const

export const getProjects = () => axios.get<ProjectDto[]>(PATH)
  .then(result => result.data.map(dto => new Project(dto)))

export const createProject = (project: NewProject) =>
  axios.post<string>(PATH, toFormData(project))
    .then(result => result.data)

export const savePartialProject = ({ id, ...project }: Partial<Project> & { id: string }) =>
  axios.patch<ProjectDto>(`${PATH}/${id}`, toFormData(project))
    .then(result => new Project(result.data))

export const getProjectPublic = (id: string) =>
  axios.get<ProjectDto>(`${PATH}/public/${id}`).then(result => new Project(result.data))

export const getProject = (id: string) =>
  axios.get<ProjectDto>(`${PATH}/${id}`).then(result => new Project(result.data))

export const getProjectTags = () =>
  axios.get<string[]>(`${PATH}/tags/all`)
    .then(response => response.data.map(dto => dto))

export const getProjectArtists = () =>
  axios.get<string[]>(`${PATH}/artists/all`)
    .then(response => response.data.map(dto => dto))

export const uploadAttachment = (project: Partial<Project>,
  updateProgress: (percent: number, loaded: number, total: number) => void,
  cancelToken: CancelTokenSource) =>
  axios.post<string>(`${PATH}/${project.id}/attachment`, toFormData(project), {
    cancelToken: cancelToken.token,
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const { loaded, total } = progressEvent
      const percent = Math.floor((loaded * 100) / total)
      updateProgress(percent, loaded, total)
    } }).then(response => response.data)

export const deleteAttatchment = (filename: string, projectId: string) =>
  axios.delete(`${PATH}/${projectId}/attachment?filename=${filename}`)

export const inviteProjectCollaborator = (projectId: string, email: string, role: string) =>
  axios.post<ProjectDto>(`${PATH}/${projectId}/${email.toLowerCase()}/${role}`)
    .then(response => new Project(response.data))

export const addToProjectByLink = (projectId: string, hash: string) =>
  axios.put<ProjectDto>(`${PATH}/share/${projectId}/${hash}`)
    .then(response => new Project(response.data))

export const deleteProject = (project: Partial<Project>) =>
  axios.delete<string>(`${PATH}/delete/${project.id}`)
    .then(result => result.data)

export const removeCollaboratorProject = (project: Partial<Project>, collaborator: Organisation) =>
  axios.delete<ProjectDto>(`${PATH}/deleteCollaborator/${project.id}/${collaborator.id}`)
    .then(response => new Project(response.data))

export const updateCollaboratorPermissions = (
  project: Partial<Project>,
  collaborator: Collaborator,
  collaboratorPermissions: string[]
) =>
  axios.patch<ProjectDto>(`${PATH}/updateCollaborator/${project.id}/${collaborator.account.id}`,
    collaboratorPermissions)
    .then(response => new Project(response.data))

export const getAllCollaborators = () =>
  axios.get<OrganisationDto[]>(`${PATH}/splits/current`)
    .then(response => response.data.map(dto => new Organisation(dto)))

export const firstProject = () =>
  axios.post<string>(`${PATH}/firstProject`)
    .then(response => response)

export const sendMessageProject = (id: string, message: ProjectUpdate) =>
  axios.post<ProjectDto>(`${PATH}/message/${id}`, message)
    .then(response => new Project(response.data))

export const getLinksFromApi = (id: string) =>
  axios.get<ProjectDto>(`${PATH}/scanForLinks/${id}`)
    .then(response => new Project(response.data))

export const addTransactionProject = (recordingId: string, recoupItem: Recoupment) =>
  axios.put<ProjectDto>(`${PATH}/transaction/${recordingId}`, recoupItem)
    .then(response => new Project(response.data))

export const createSingles = (recordingIds: string[]) =>
  axios.put<ProjectDto>(`${PATH}/create-singles?${recordingIds.map((item, index) => `ids[${index}]=${item}`).join('&')}`)
    .then(response => new Project(response.data))

export const importRecordings = (projectId: string, recordingIds: string[]) =>
  axios.put<ProjectDto>(`${PATH}/import/${projectId}?${recordingIds.map((item, index) => `ids[${index}]=${item}`).join('&')}`)
    .then(response => new Project(response.data))

export const gptArtwork = (projectId: string, prompt: string) =>
  axios.put<ProjectDto>(`${PATH}/gpt-artwork/${projectId}`, { prompt })
    .then(result => new Project(result.data))

export const importReleaseFunction = (type: string, id: string, orgId: string) =>
  axios.put<boolean>(`${PATH}/import-transfer`,
    { spotifyType: type, spotifyId: id, id: orgId })
    .then(response => response.data)
