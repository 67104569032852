/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Stack } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect } from 'react'

import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly setGa: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setFbp: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setFbc: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setUtm_source: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setUtm_medium: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setUtm_campaign: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setUtm_content: React.Dispatch<React.SetStateAction<string | undefined>>
  readonly setUtm_term: React.Dispatch<React.SetStateAction<string | undefined>>
}

const CookiesHandlerSignup = (props: Props) => {
  const {
    currentOrganisation,
  } = useAuth()

  // Google Analytics
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.ga === undefined) {
      const googleAnalyticsCookie = Cookies.get('_ga')
      if (googleAnalyticsCookie) {
        const clientId = googleAnalyticsCookie.slice(6)
        props.setGa(clientId)
      }
    }
  }, [currentOrganisation?.ga])

  // Meta Pixel fbp cookie
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.fbp === undefined) {
      const fbpCookie = Cookies.get('_fbp')
      if (fbpCookie) {
        const clientId = fbpCookie
        props.setFbp(clientId)
      }
    }
  }, [currentOrganisation?.fbp])

  // Meta Pixel fbc cookie
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.fbc === undefined) {
      const fbcCookie = Cookies.get('_fbc')
      if (fbcCookie) {
        const clientId = fbcCookie
        props.setFbc(clientId)
      }
    }
  }, [currentOrganisation?.fbc])

  // UTM source
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_source === undefined) {
      const utmCookieCookie = Cookies.get('utm_source')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        props.setUtm_source(clientId)
      }
    }
  }, [currentOrganisation?.utm_source])

  // UTM medium
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_medium === undefined) {
      const utmCookieCookie = Cookies.get('utm_medium')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        props.setUtm_medium(clientId)
      }
    }
  }, [currentOrganisation?.utm_medium])

  // UTM campaign
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_campaign === undefined) {
      const utmCookieCookie = Cookies.get('utm_campaign')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        props.setUtm_campaign(clientId)
      }
    }
  }, [currentOrganisation?.utm_campaign])

  // UTM content
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_content === undefined) {
      const utmCookieCookie = Cookies.get('utm_content')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        props.setUtm_content(clientId)
      }
    }
  }, [currentOrganisation?.utm_content])

  // UTM term
  useEffect(() => {
    if (currentOrganisation && currentOrganisation.utm_term === undefined) {
      const utmCookieCookie = Cookies.get('utm_term')
      if (utmCookieCookie) {
        const clientId = utmCookieCookie
        props.setUtm_term(clientId)
      }
    }
  }, [currentOrganisation?.utm_term])

  return (
    <Stack />
  )
}

export default CookiesHandlerSignup
