/* eslint-disable unicorn/filename-case */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
const pricingFeaturesList = [
  {
    title: 'Features',
    featureItem: [
      {
        title: 'Storage',
        free: '5 Gb',
        creator: '1 Tb',
        creatorPro: 'Unlimited',
        enterprise: 'Unlimited',
        enterprisePlus: 'Unlimited',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Distribution commission',
        free: '20%',
        creator: '15%',
        creatorPro: '10%',
        enterprise: '5%',
        enterprisePlus: '2.9%',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Publishing & Neighboring commission',
        free: '15%',
        creator: '15%',
        creatorPro: '15%',
        enterprise: '15%',
        enterprisePlus: '15%',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'YouTube MCN commission',
        free: '15%',
        creator: '15%',
        creatorPro: '15%',
        enterprise: '15%',
        enterprisePlus: '15%',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Logins',
        free: '1',
        creator: 'Unlimited*',
        creatorPro: 'Unlimited*',
        enterprise: 'Unlimited*',
        enterprisePlus: 'Unlimited*',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: false,
      },
      {
        title: 'Release planning',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        enterprisePlus: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Smart links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Short links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: true,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Unlimited music distribution',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Distribute unlimited artists',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Distribute unlimited labels',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: '1 Analytics label or artist profile',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Daily Spotify streams',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Audience, UGC, and playlist analytics',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom link subdomain',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Pre-save links',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: true,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Pre-save subscriptions',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Export fan emails',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: true,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Tracking pixels',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom marketing link domain',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Meta Conversions API integration',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'TikTok Events API integration',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: true,
        enterprisePlusIcon: true,
      },
      {
        title: 'Royalty recoups',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
      {
        title: 'Unlimited analytics profiles',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
      {
        title: 'Territory carving',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
      {
        title: 'Custom user roles',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
      {
        title: 'Personalized onboarding',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
      {
        title: 'Dedicated account manager',
        free: '',
        creator: '',
        creatorPro: '',
        enterprise: '',
        freeIcon: false,
        creatorIcon: false,
        creatorProIcon: false,
        enterpriseIcon: false,
        enterprisePlusIcon: true,
      },
    ],
  },
]

export default pricingFeaturesList
