/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/Edit'
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import { Avatar, CardActionArea, CircularProgress, Divider, IconButton, ListItemIcon, Menu, MenuItem, Paper, Stack, Tooltip, Typography } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'

import { baseUrlLinksApi } from 'src/api/files'
import { savePartialProject } from 'src/api/projects'
import { FileUploadButtonBase } from 'src/components/form-elements/FileUpload'
import PitchModal from 'src/components/pages/Projects/PitchModal/PitchModal'
import ViewPitchModal from 'src/components/pages/Projects/PitchModal/ViewPitchModal'
import EditLinksProjectModal from 'src/components/pages/Projects/ViewProject/EditDetails/EditLinksModal'
import GenerateArtworkModal from 'src/components/pages/Projects/ViewProject/EditDetails/GenerateArtwork'
import ShareModal from 'src/components/pages/Projects/ViewProject/ShareModal/ShareModal'
import TakedownModal from 'src/components/pages/Projects/ViewProject/TakedownModal'
import ViewProjectNavDetails from 'src/components/pages/Projects/ViewProject/ViewDetailsNavigation'
import ViewProjectNavEmbed from 'src/components/pages/Projects/ViewProject/ViewProjectEmbed'
import SocialAssetModal from 'src/components/pages/SocialAssets/SocialAssetModal'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Distribution } from 'src/models/Distribution'
import { Permissions } from 'src/models/Organisation'
import type { Pitch } from 'src/models/Pitch'
import type Project from 'src/models/Project'
import { PROJECT_STATUS } from 'src/models/Project'

export function getColor(selected: string) {
  switch (selected) {
    case PROJECT_STATUS[0].toString():
      return 'success'
    case PROJECT_STATUS[1].toString():
      return 'warning'
    case PROJECT_STATUS[2].toString():
      return 'secondary'
    case PROJECT_STATUS[3].toString():
      return 'error'
    case PROJECT_STATUS[4].toString():
      return 'primary'
    default:
      return 'primary'
  }
}

type Props = {
  readonly project?: Project
  readonly setDistributeModalOpen: () => void
  readonly mobile?: boolean
  readonly pitch?: Pitch
  readonly setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  readonly setPitchItem: React.Dispatch<React.SetStateAction<Pitch | undefined>>
  readonly setUpdateBoard: React.Dispatch<React.SetStateAction<boolean>>
  readonly currentTab: string
  readonly setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  readonly distributionItem: Distribution | undefined
  readonly updateDistribution: () => void
}

const imageSize = '280px!important'

const ViewProjectDetails = (props: Props) => {
  const { currentAccountPermissions, currentOrganisation } = useAuth()
  const [shareModal, setShareModal] = useState(false)
  const [editLinks, setEditLinks] = useState(false)
  const [pitchModal, setPitchModal] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [assetModal, setAssetModal] = useState(false)
  const [openTakedown, setOpenTakedown] = useState(false)
  const [openGpt, setOpenGpt] = useState(false)

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const openMenu = Boolean(anchorElement)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorElement(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElement(null)
  }

  const [anchorElementArtwork, setAnchorElementArtwork] = useState<HTMLElement | null>(null)
  const openMenuArtwork = Boolean(anchorElementArtwork)

  const handleMenuOpenArtwork = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementArtwork(event.currentTarget)
  }
  const handleMenuCloseArtwork = () => {
    setAnchorElementArtwork(null)
  }
  const [artworkUrl, setArtworkUrl] = useState<string>()
  useEffect(() => {
    setArtworkUrl(props.project?.projectArtworkUrl)
  }, [props.project?.projectArtworkUrl])

  return (
    <>
      {openGpt && props.project &&
      <GenerateArtworkModal
        close={() => setOpenGpt(false)}
        open={openGpt}
        project={props.project}
        setProject={props.setProject}
      />}
      {props.project &&
      <ShareModal
        close={() => setShareModal(false)}
        open={shareModal}
        project={props.project}
      />}
      {props.pitch && props.project &&
      <ViewPitchModal
        close={() => setPitchModal(false)}
        open={pitchModal}
        pitch={props.pitch}
        project={props.project}
      />}
      {props.project && !props.pitch &&
      <PitchModal
        close={() => setPitchModal(false)}
        open={pitchModal}
        project={props.project}
        setPitchItem={props.setPitchItem}
      />}
      {props.project &&
      <EditLinksProjectModal
        close={() => setEditLinks(false)}
        open={editLinks}
        project={props.project}
        setProject={props.setProject}
        setUpdateBoard={props.setUpdateBoard}
      />}
      {props.project && assetModal &&
      <SocialAssetModal
        close={() => setAssetModal(false)}
        imageMainUrl={props.project.artworkPreviewUrl}
        imageSecondaryUrl=''
        open={assetModal}
        platform='Spotify'
        project={props.project}
        text='Out now'
      />}
      <Menu
        anchorEl={anchorElementArtwork}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={() => null}
        onClose={handleMenuCloseArtwork}
        open={openMenuArtwork}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <MenuItem
          component='a'
          disabled={!artworkUrl}
          href={`${baseUrlLinksApi}/files/download?fileName=${encodeURIComponent(artworkUrl ?? '')}`}
          target='_blank'
        >
          <ListItemIcon color='primary'>
            <CloudDownloadRoundedIcon color='primary' fontSize='medium' />
          </ListItemIcon>
          Download Artwork
        </MenuItem>
        <FileUploadButtonBase
          disabled={!props.project || props.project.distributed}
          id='upload-artwork-picture'
          onChange={async (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0]
            if (file && props.project &&
                ((/\.(jpg)$/i).test(file.name) ||
                (/\.(jpeg)$/i).test(file.name) ||
                (/\.(png)$/i).test(file.name) ||
                (/\.(png)$/i).test(file.name) ||
                (/\.(jpg)$/i).test(file.name) ||
                (/\.(jpeg)$/i).test(file.name))) {
              setLoadingImage(true)
              await savePartialProject({ ...props.project, projectArtwork: file })
                .then(item => props.setProject(item))
                .finally(() => {
                  setLoadingImage(false)
                  handleMenuCloseArtwork()
                })
            }
          }}
          sx={{
            width: imageSize,
            height: imageSize,
          }}
        >
          <MenuItem
            disabled={!props.project || props.project.distributed}
          >
            <ListItemIcon color='secondary'>
              <CloudUploadRoundedIcon
                color='secondary'
                fontSize='medium'
              />
            </ListItemIcon>
            Replace Artwork
          </MenuItem>
        </FileUploadButtonBase>
        <MenuItem
          disabled={!props.project || props.project.distributed}
          onClick={() => {
            setOpenGpt(true)
            handleMenuCloseArtwork()
          }}
        >
          <ListItemIcon
            sx={{
              color: 'rgba(186,120,255,1)',
            }}
          >
            <AutoAwesomeRoundedIcon
              fontSize='medium'
              sx={{
                color: 'rgba(186,120,255,1)',
              }}
            />
          </ListItemIcon>
          AI Generate Artwork
        </MenuItem>
      </Menu>
      <Stack
        alignItems='center'
        height={1}
        minWidth={props.mobile ? 1 : 280}
        sx={{
          overflowY: 'auto',
          overflowX: 'inherit',
        }}
        width={props.mobile ? 1 : 280}
      >
        <Stack
          alignItems='center'
          direction='column'
          flexShrink={0}
          paddingTop={0}
          paddingX={0}
          spacing={2}
          width={props.mobile ? 1 : imageSize}
        >
          <Paper
            elevation={0}
            sx={{
              objectFit: 'contain',
              borderRadius: '0px',
              cursor: 'pointer',
              position: 'relative',
              width: imageSize,
              height: imageSize,
              padding: 0,
              transition: '0.3s',
              '&:hover .MuiAvatar-root': {
                opacity: props.project?.distributed || loadingImage
                  ? 1
                  : 0.2,
              }
              ,
              '&:hover .MuiSvgIcon-root': {
                opacity: 1,
              },
              ':hover':
                {
                  zIndex: 1,
                  backgroundColor: theme => props.project?.distributed || loadingImage
                    ? theme.palette.background.input
                    : theme.palette.primary.containedResting,
                  opacity: props.project?.distributed || loadingImage
                    ? 1
                    : 0.8,
                },
            }}
          >
            <CardActionArea onClick={event => handleMenuOpenArtwork(event)}>
              {loadingImage
                ? <Avatar
                  sx={{
                    width: imageSize,
                    height: imageSize,
                    transition: '0.3s',
                    background: theme => theme.palette.background.default,
                    borderRadius: '0px',
                    zIndex: 1,
                  }}
                  variant='rounded'
                >
                  <CircularProgress color='primary' sx={{ width: 120, height: 120 }} />
                </Avatar>
                : <Avatar
                  src={props.project?.artworkPreviewUrl}
                  sx={{
                    width: imageSize,
                    height: imageSize,
                    transition: '0.3s',
                    borderRadius: '0px',
                    background: theme => theme.palette.background.default,
                    zIndex: 1,
                  }}
                  variant='rounded'
                >
                  <MusicNoteRoundedIcon
                    sx={{
                      fontSize: imageSize,
                      color: theme => theme.palette.action.disabled,
                    }}
                  />
                </Avatar>}
              {!props.project?.distributed && !loadingImage &&
                <Stack
                  alignItems='center'
                  height='100%'
                  justifyContent='center'
                  left={0}
                  marginLeft='auto'
                  marginRight='auto'
                  position='absolute'
                  right={0}
                  top={0}
                  width={256}
                  zIndex={9}
                >
                  <EditIcon
                    sx={{
                      position: 'absolute',
                      zIndex: 9,
                      color: 'common.white',
                      opacity: 0,
                      transition: '0.3s',
                      fontSize: '64px!important',
                    }}
                  />
                </Stack>}
            </CardActionArea>
          </Paper>

          <Stack direction='column' spacing={2} width={1}>
            <Stack
              alignItems='center'
              direction='column'
              spacing={1}
              width={1}
            >
              <Typography lineHeight={1.2} textAlign='center' variant='h4' width='100%' zIndex={1}>
                {props.project?.title}
              </Typography>

              <Stack alignItems='center' direction='row' id='project_todo' spacing={1}>
                <Tooltip title='Share'>
                  <div>
                    <IconButton
                      onClick={() => setShareModal(true)}
                      size='small'
                    >
                      <IosShareRoundedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title='Generate assets'>
                  <div>
                    <IconButton
                      color='secondary'
                      disabled={!props.project?.artworkPreviewUrl || props.project.artworkPreviewUrl.length === 0}
                      onClick={() => setAssetModal(true)}
                      size='small'
                    >
                      <AddPhotoAlternateRoundedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
                {props.project?.distributed
                  ? <Tooltip
                    title={props.distributionItem?.status === 'Distributed'
                      ? 'This release is distributed on Releese'
                      : props.distributionItem?.status === 'Taken Down'
                        ? 'This release has been taken down'
                        : props.distributionItem?.status === 'Under review'
                          ? 'This release is under review'
                          : props.distributionItem?.status === 'Changes requested'
                            ? 'Changes are requested, please check your email'
                            : ''}
                  >
                    <CheckCircleRoundedIcon
                      color={props.distributionItem?.status === 'Distributed'
                        ? 'success'
                        : props.distributionItem?.status === 'Taken Down'
                          ? 'error'
                          : props.distributionItem?.status === 'Under review'
                            ? 'primary'
                            : props.distributionItem?.status === 'Changes requested'
                              ? 'warning'
                              : 'inherit'}
                      fontSize='large'
                    />
                  </Tooltip> : null}
                {props.project?.organisation?.id === currentOrganisation?.id && !props.project?.distributed &&
                <Tooltip title='Distribute'>
                  <div>
                    <IconButton
                      color='primary'
                      disabled={!currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES)}
                      onClick={props.setDistributeModalOpen}
                      size='small'
                    >
                      <PublishRoundedIcon />
                    </IconButton>
                  </div>
                </Tooltip>}
                {props.project?.organisation?.id === currentOrganisation?.id
                  ? <Tooltip title={props.pitch ? 'View marketing pitch' : 'Submit marketing pitch'}>
                    <IconButton
                      color='info'
                      disabled={!currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES)}
                      onClick={() => setPitchModal(true)}
                      size='small'
                    >
                      <CampaignRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  : null}
                {props.project?.organisation?.id === currentOrganisation?.id && props.project?.distributed
                  ? <Tooltip title='Manage Distribution'>
                    <IconButton
                      disabled={!currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES)}
                      onClick={event => handleMenuClick(event)}
                      size='small'
                    >
                      <MoreHorizRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  : null}
              </Stack>
            </Stack>

          </Stack>

        </Stack>
        <ViewProjectNavDetails
          currentTab={props.currentTab}
          distributionItem={props.distributionItem}
          project={props.project}
          setCurrentTab={props.setCurrentTab}
        />

        <Stack marginY={2} paddingX={3} width={1}>
          <Divider
            sx={{
              width: 1,
            }}
          />
        </Stack>

        <ViewProjectNavEmbed
          currentTab={props.currentTab}
          project={props.project}
          setCurrentTab={props.setCurrentTab}
          setProject={props.setProject}
        />

      </Stack>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClick={handleClose}
        onClose={handleClose}
        open={openMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem
          disabled={props.distributionItem?.status !== 'Distributed' ||
            !currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES)}
          onClick={() => setOpenTakedown(true)}
        >
          <ListItemIcon>
            <DeleteRoundedIcon color='error' fontSize='medium' />
          </ListItemIcon>
          Takedown project
        </MenuItem>
      </Menu>

      {props.distributionItem &&
      <TakedownModal
        close={() => {
          props.updateDistribution()
          setOpenTakedown(false)
        }}
        distribution={props.distributionItem}
        open={openTakedown}
      />}
    </>
  )
}
export default ViewProjectDetails
