/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded'
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded'
import FolderRoundedIcon from '@mui/icons-material/FolderRounded'
import ForumRoundedIcon from '@mui/icons-material/ForumRounded'
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'

import { useAuth } from 'src/components/providers/AuthProvider'
import type { Distribution } from 'src/models/Distribution'
import { Permissions } from 'src/models/Organisation'
import type Project from 'src/models/Project'

type Props = {
  readonly currentTab: string
  readonly setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  readonly project?: Project
  readonly distributionItem: Distribution | undefined
}

const ViewProjectNavDetails = (props: Props) => {
  const {
    currentOrganisation,
    currentAccountPermissions,
  } = useAuth()
  const collaboratorItem = props.project?.collaborators?.find(item => item.account.id === currentOrganisation?.id)

  return (
    <List dense id='project-tabs' sx={{ width: 1 }}>
      <Stack width={1}>

        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            onClick={() => props.setCurrentTab('8')}
            selected={props.currentTab === '8'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <LibraryMusicRoundedIcon
                color={props.currentTab === '8' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Overview</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding
          id='nav-release'
        >
          <ListItemButton
            onClick={() => props.setCurrentTab('2')}
            selected={props.currentTab === '2'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <MusicNoteRoundedIcon
                color={props.currentTab === '2' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Recordings</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            disabled={!currentAccountPermissions?.includes(Permissions.VIEW_TASKBOARDS) ||
            collaboratorItem?.restrictions.includes('Tasks')}
            onClick={() => props.setCurrentTab('1')}
            selected={props.currentTab === '1'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <TaskAltRoundedIcon
                color={props.currentTab === '1' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Tasks</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding

        >
          <ListItemButton
            disabled={collaboratorItem?.restrictions.includes('Messages')}
            onClick={() => props.setCurrentTab('11')}
            selected={props.currentTab === '11'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <ForumRoundedIcon
                color={props.currentTab === '11' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Messages</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding

        >
          <ListItemButton
            disabled={collaboratorItem?.restrictions.includes('Files')}
            onClick={() => props.setCurrentTab('3')}
            selected={props.currentTab === '3'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <FolderRoundedIcon
                color={props.currentTab === '3' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Files</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding

        >
          <ListItemButton
            disabled={!currentAccountPermissions?.includes(Permissions.VIEW_MARKETING_LINKS) ||
              collaboratorItem?.restrictions.includes('Smart Links')}
            onClick={() => props.setCurrentTab('5')}
            selected={props.currentTab === '5'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <PhonelinkRoundedIcon
                color={props.currentTab === '5' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Smart Links</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding
          id='distribute-button'
        >
          <ListItemButton
            disabled={!currentAccountPermissions?.includes(Permissions.DISTRIBUTE_RELEASES) ||
              (!props.project?.distributed && currentOrganisation?.id !== props.project?.organisation?.id) ||
              collaboratorItem?.restrictions.includes('Distribution')}
            onClick={() => props.setCurrentTab('9')}
            selected={props.currentTab === '9'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <PublishRoundedIcon
                color={props.currentTab === '9' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Distribution</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
            {props.distributionItem?.status === 'Changes requested' &&
            <ListItemAvatar
              sx={{
                background: theme => theme.palette.warning.main,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '64px',
                width: '30px',
                height: '30px',
              }}
            >
              <WarningRoundedIcon
                sx={{
                  fontSize: 18,
                  color: 'white',
                }}
              />
            </ListItemAvatar>}
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding

        >
          <ListItemButton
            disabled={!currentAccountPermissions?.includes(Permissions.VIEW_EARNINGS) ||
            collaboratorItem?.restrictions.includes('Earnings')}
            onClick={() => props.setCurrentTab('7')}
            selected={props.currentTab === '7'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <AttachMoneyRoundedIcon
                color={props.currentTab === '7' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Finances</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          dense
          disablePadding
        >
          <ListItemButton
            disabled={!currentAccountPermissions?.includes(Permissions.VIEW_ANALYTICS) ||
              (!props.project?.distributed && !props.project?.organisation?.trustedAnalytics) ||
              props.project.releaseDate > new Date() ||
              collaboratorItem?.restrictions.includes('Analytics')}
            onClick={() => props.setCurrentTab('6')}
            selected={props.currentTab === '6'}
            sx={{ borderRadius: 0 }}
          >
            <ListItemAvatar>
              <AnalyticsRoundedIcon
                color={props.currentTab === '6' ? 'primary' : 'action'}
                sx={{
                  fontSize: 18,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={<Typography variant='body2'>Analytics</Typography>}
              sx={{ wordBreak: 'break-word' }}
            />
          </ListItemButton>
        </ListItem>
      </Stack>

    </List>
  )
}

export default ViewProjectNavDetails
