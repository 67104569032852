import { Button, Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import { v4 } from 'uuid'

type Props = {
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly starter: number
  readonly month: number
}

const features = [
  'Editorial pitching to Spotify & more',
  'Advanced audience stats and insights',
  'Smart Links, Bio Link, & Pre-saves',
  'Publishing & neighboring rights collection',
  'Keep 85% of earnings',
]

const StarterCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        width: 1,
      }}
      variant='outlined'
    >
      <CardActionArea
        onClick={props.handleBilling(props.starter)}
        sx={{ height: 1 }}
      >
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2} width={1}>
          <Stack paddingTop={2}>
            <Typography fontWeight='normal' variant='h2'>
              Starter
            </Typography>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? props.billingCycle * props.starter
                  : props.billingCycle * props.starter / 12 * 0.8}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed annually
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Tools to start managing your music
          </Typography>
          <Button
            color='primary'
            fullWidth
          >
            Get started
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h4'>
              Unlimited distribution, and
            </Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            {features.map(item =>
              <Stack alignItems='center' direction='row' key={v4()} spacing={1}>
                <Typography variant='body2'>
                  <span>
                    ✓
                  </span>
                  <span style={{ paddingLeft: 8 }}>
                    {item}
                  </span>
                </Typography>
              </Stack>)}
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default StarterCard
