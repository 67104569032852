/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import type { ReactourStep, ReactourStepContentArgs } from 'reactour'
import Tour from 'reactour'

import TourHeader from 'src/components/pages/Tour/TourComponents/TourHeader'
import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import { useAuth } from 'src/components/providers/AuthProvider'

const SetupTourSplits = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { currentAccount, currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [open, setOpen] = useState(false)

  const closeTour = () => {
    setOpen(false)
    if (currentOrganisation) {
      void updateCurrentOrganisation({
        ...currentOrganisation,
        onboarding: {
          ...currentOrganisation.onboarding,
          setup: false,
        },
      })
    }
    navigate('/splits')
  }

  useEffect(() => {
    if (currentOrganisation?.onboarding.setup) {
      setOpen(currentOrganisation.onboarding.setup)
    }
  }, [currentOrganisation?.onboarding.setup])

  const myRef = useRef(document.getElementById('splits'))

  useEffect(() => {
    const element = document.getElementById('splits')
    if (element) myRef.current = element

    if (myRef.current) {
      myRef.current.addEventListener('click', closeTour)
    }
    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener('click', closeTour)
      }
    }
  }, [myRef.current, closeTour])

  const steps: ReactourStep[] = [
    {
      content: ({ goTo, step }: ReactourStepContentArgs) =>
        <TourHeader
          description='We built Releese to do one thing: help artists gain more streams, more fans, and more royalties.
          Releese can be used to track your audience, distribute your music, collect your publishing, and accumulate
          recurring pre-saves.'
          goTo={goTo}
          step={step}
          title={`Welcome to Releese, ${currentAccount.firstName}`}
        />
      ,
      position: 'right',
      style: {
        width: 500,
        maxWidth: 500,
        backgroundColor: theme.palette.background.default,
        boxShadow: 'rgba(1, 1, 1, 0.7) 0px 0.5em 3em',
      },
    },
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description='You can go to the Splits page to accept your split so you can start collecting your earnings.'
          final
          finalAction={() => closeTour()}
          finalText='Take me to my splits'
          goTo={goTo}
          step={step}
          title='Get started with Releese'
        />
      ,
      position: 'right',
      selector: '#splits',
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.default,
        boxShadow: 'rgba(1, 1, 1, 0.7) 0px 0.5em 3em',
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask
      disableFocusLock
      disableKeyboardNavigation
      isOpen={open}
      onRequestClose={() => closeTour()}
      rounded={16}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default SetupTourSplits
