/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { Alert, Backdrop, Button, Card, Chip, Grid, InputAdornment, Paper, Stack, TextField, Typography, useTheme } from '@mui/material'
import type { ChangeEvent } from 'react'

import { FileUploadButtonBase, FileUploadIconButtonAllTypes } from 'src/components/form-elements/FileUpload'
import EditProjectMetadataFrontend from 'src/components/pages/Projects/UpdateModal/DistributeModalComponents/EditProjectMetadataFrontend'
import ModerationMessageItem from 'src/components/pages/Projects/UpdateModal/Moderation/ModerationMessage'
import { generateTitleRecording } from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/RecordingsTab/Components/EditRecordingComponent'
import { NavLink } from 'src/components/SafeRouterLink'
import { distributionErrorCopyright } from 'src/data/distributionErrors'
import type { Distribution, Platform, Territory } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import type Recording from 'src/models/Recording'

type Props = {
  readonly territories: Territory[]
  readonly stores: Platform[]
  readonly project?: Project
  readonly recordings: Recording[]
  readonly errorHandling: boolean
  readonly loading: boolean
  readonly errorMessage: string
  readonly setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
  readonly handleArtworkUpload: (event: ChangeEvent<HTMLInputElement>) => void
  readonly artworkPreview: string | null | undefined
  readonly modMessage: string
  readonly setModMessage: React.Dispatch<React.SetStateAction<string>>
  readonly distribution: Distribution
  readonly attachment: File | undefined
  readonly setAttachment: React.Dispatch<React.SetStateAction<File | undefined>>
}

const SubmitUpdate = (props: Props) => {
  const themeColors = useTheme()

  const uniqueArtists = [...new Set(props.recordings.flatMap(record =>
    record.primaryArtists.map(artist => artist.name)))]

  const duplicateIsrcError =
    new Set(props.recordings.map(item => item.ISRC).filter(item => item.length > 0)).size !==
    props.recordings.map(item => item.ISRC).filter(item => item.length > 0).length

  const allTitles = props.recordings.map(item => generateTitleRecording(undefined, item))
  const duplicateTitleError = new Set(allTitles).size !== allTitles.length

  const addMessageFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) return
    props.setAttachment(file)
  }

  return (
    <>
      <Backdrop
        open={props.loading || props.errorMessage.length > 0}
        sx={{
          zIndex: 2,
        }}
      >
        {props.errorMessage.length > 0 &&
        <Card
          sx={{
            minWidth: 300,
            minHeight: 240,
            maxWidth: 600,
          }}
        >
          <Stack alignItems='center' padding={2} spacing={4} width={1}>
            {props.errorMessage === distributionErrorCopyright
              ? <VisibilityRoundedIcon
                color='primary'
                sx={{
                  fontSize: 64,
                }}
              />
              : <ErrorRoundedIcon
                color='error'
                sx={{
                  fontSize: 64,
                }}
              />}

            <Typography textAlign='center'>
              {props.errorMessage}
            </Typography>
            <Button
              component={NavLink}
              to='/projects/projects'
            >
              Go back to releases
            </Button>
          </Stack>
        </Card>}
      </Backdrop>

      <Stack marginBottom={4} spacing={4} width={1}>
        <Stack spacing={1} width={1}>
          {duplicateIsrcError &&
          <Stack width={1}>
            <Alert
              severity='error'
              variant='filled'
            >
              Your release has 2 releases with the same ISRC code, please change them. Albums cannot contain duplicate
              products.
            </Alert>
          </Stack>}

          {duplicateTitleError &&
          <Stack width={1}>
            <Alert
              severity='error'
              variant='filled'
            >
              Your release has 2 releases with the same title, please set a different subversion for these titles.
            </Alert>
          </Stack>}

          {uniqueArtists.length > 4 &&
            <Stack width={1}>
              <Alert
                severity='info'
                variant='filled'
              >
                Your release has more than 4 Primary Artists, it will be marked as a Various Artists release on most
                platforms.
              </Alert>
            </Stack>}

          {props.distribution.status === 'Changes requested' &&
          <Stack spacing={1} width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography lineHeight={1.2} variant='h3'>
                Changes Requested
                {' '}
                <WarningRoundedIcon
                  color='warning'
                  sx={{
                    marginBottom: '4px!important',
                  }}
                />
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} spacing={1} width={1}>
                {props.distribution.moderation.map(item =>
                  <ModerationMessageItem
                    key={item.id}
                    moderationMessage={item}
                  />)}
                {props.attachment &&
                  <Card
                    elevation={0}
                    variant='outlined'
                  >
                    <Stack padding={1}>
                      <Typography color='textSecondary' variant='body2'>
                        {props.attachment.name}
                      </Typography>
                    </Stack>
                  </Card>}
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position='end' style={{ height: '100%' }}>
                      <FileUploadIconButtonAllTypes
                        id='upload-attachment'
                        onChange={addMessageFile}
                      >
                        <AttachFileRoundedIcon />
                      </FileUploadIconButtonAllTypes>
                    </InputAdornment>,
                  }}
                  autoComplete='off'
                  fullWidth
                  minRows={3}
                  multiline
                  onChange={event => props.setModMessage(event.target.value)}
                  placeholder='Please include any details you wish to pass on to our moderation team'
                  value={props.modMessage}
                />
              </Stack>
            </Card>
          </Stack>}

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Overview
              </Typography>
              {props.errorHandling &&
              <Chip color='error' label='Errors found' size='small' variant='filled' />}
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} width={1}>
                <Stack padding={2} spacing={1} width={192}>
                  <FileUploadButtonBase
                    id='upload-new-project-artwork'
                    onChange={props.handleArtworkUpload}
                    sx={{
                      width: '160px',
                    }}
                  >
                    {props.artworkPreview
                      ? <Card
                        elevation={0}
                        sx={{
                          padding: 0,
                          border: theme => `1px dashed ${theme.palette.primary.main}`,
                          background: theme => theme.palette.background.input,
                          height: 160,
                          width: 160,
                          '&:hover': {
                            opacity: 0.5,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                          },
                        }}
                      >
                        <img
                          alt='Artwork'
                          height='160px'
                          src={props.artworkPreview}
                          style={{
                            borderRadius: 16,
                            objectFit: 'cover',
                          }}
                          width='160px'
                        />
                      </Card>
                      : <Paper
                        elevation={0}
                        sx={{
                          padding: 0,
                          border: theme => `1px dashed ${theme.palette.primary.main}`,
                          background: theme => theme.palette.background.input,
                          height: 160,
                          width: 160,
                          '&:hover': {
                            opacity: 0.5,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                          },
                        }}
                      >
                        <MusicNoteRoundedIcon
                          sx={{
                            fontSize: 160,
                            color: theme => theme.palette.action.disabled,
                          }}
                        />
                      </Paper>}
                  </FileUploadButtonBase>
                </Stack>
                <Stack paddingX={2}>
                  {props.project &&
                  <EditProjectMetadataFrontend
                    distributing
                    project={props.project}
                    setProject={props.setProject}
                    updatedFields={value => props.setProject(value)}
                  />}
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>

        <Stack width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Territories
            </Typography>
            {props.territories.length > 0
              ? <Chip color='success' label='Ready' size='small' variant='filled' />
              : <Chip color='error' label='Error' size='small' variant='filled' />}
          </Stack>

          <Card variant='outlined'>
            <Stack padding={4} width={1}>
              <Grid container rowSpacing={2} width={1}>
                <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                    <Stack width={1}>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Number of territories
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Typography variant='body2'>
                        {props.territories.length.toString()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>

        <Stack width={1}>
          <Stack alignItems='center' direction='row' spacing={1}>
            <Typography variant='h3'>
              Stores
            </Typography>
            {props.stores.length > 0
              ? <Chip color='success' label='Ready' size='small' variant='filled' />
              : <Chip color='error' label='Error' size='small' variant='filled' />}
          </Stack>
          <Card variant='outlined'>
            <Stack padding={4} width={1}>
              <Grid container rowSpacing={2} width={1}>

                <Grid item lg={6} md={6} sm={6} xl={6} xs={12}>
                  <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1} width={0.8}>
                    <Stack width={1}>
                      <Typography color={themeColors.palette.text.label} variant='body2'>
                        Number of stores
                      </Typography>
                    </Stack>
                    <Stack width={1}>
                      <Typography variant='body2'>
                        {props.stores.length.toString()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  )
}

export default SubmitUpdate
