import { Button, Card, CardActionArea, darken, Stack, Typography,  useTheme } from '@mui/material'
import { v4 } from 'uuid'

type Props = {
  readonly billingCycle: number
  readonly handleBilling: (step: number) => () => void
  readonly pro: number
  readonly month: number
}

const features = [
  'Unlimited user logins',
  'Custom Pre-save link domain',
  'Advanced pixel integrations',
  'Remove all Releese branding',
  'Keep 95% of earnings',
]

const ProCard = (props: Props) => {
  const themeItem = useTheme()
  return (
    <Card
      sx={{
        position: 'relative',
        width: 1,
      }}
      variant='outlined'
    >
      <CardActionArea onClick={props.handleBilling(props.pro)} sx={{ height: 1 }} >
        <Stack alignItems='flex-start' direction='column' height={1} padding={2} spacing={2} width={1}>
          <Stack paddingTop={2}>
            <Stack alignItems='center' direction='row' spacing={2}>
              <Typography fontWeight='normal' variant='h2'>
                Pro
              </Typography>
            </Stack>
            <Stack alignItems='flex-start' direction='column' spacing={1}>
              <Typography fontFamily='Domaine-Display' lineHeight={1} variant='h1'>
                <span style={{ fontFamily: 'Matter' }}>
                  $
                </span>
                {props.billingCycle === props.month
                  ? props.billingCycle * props.pro
                  : props.billingCycle * props.pro / 12 * 0.8}
              </Typography>
              {props.billingCycle === props.month
                ? <Typography variant='body1'>
                  / month
                </Typography>
                : <Typography variant='body1'>
                  / month, billed annually
                </Typography>}
            </Stack>
          </Stack>
          <Typography
            color={themeItem.palette.text.secondary}
            variant='body2'
          >
            Ultimate toolkit for scaling catalogs
          </Typography>
          <Button
            color='primary'
            fullWidth
            sx={{
              background: '#d9bc66',
              ':hover': {
                background: darken('#d9bc66', 0.25),
              },
            }}
          >
            Get started
          </Button>
          <Stack direction='column'>
            <Typography lineHeight={1.4} variant='h4'>
              Everything in Essential, and
            </Typography>
          </Stack>
          <Stack direction='column' spacing={2}>
            {features.map(item =>
              <Stack alignItems='center' direction='row' key={v4()} spacing={1}>
                <Typography variant='body2'>
                  <span>
                    ✓
                  </span>
                  <span style={{ paddingLeft: 8 }}>
                    {item}
                  </span>
                </Typography>
              </Stack>)}
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}

export default ProCard
