import EventRoundedIcon from '@mui/icons-material/EventRounded'
import { Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'

import ExplainModal from 'src/components/pages/Analytics/ExplainModal'

const EarningsDistributionNextPayout = () => {
  const themeItem = useTheme()
  const [explain, setExplain] = useState(false)

  const date = new Date().getDate() > 25
    ? new Date(new Date().setDate(25)).setMonth(new Date().getMonth() - 1)
    : new Date(new Date().setDate(25)).setMonth(new Date().getMonth() - 2)

  const payoutDate = new Date().getDate() > 25
    ? new Date(new Date().setDate(25)).setMonth(new Date().getMonth() + 1)
    : new Date(new Date().setDate(25))

  return (
    <Stack height={1} width={1}>
      <Card
        elevation={0}
        sx={{
          height: 1,
          background: theme => theme.palette.background.elevatedCard,
        }}
      >
        <CardActionArea onClick={() => setExplain(true)}>
          <Stack height={1} justifyContent='space-between' padding={2} spacing={3}>
            <Stack direction='row' spacing={2} width={1}>
              <Stack spacing={2} width={1}>
                <Typography variant='body1'>
                  Next payout
                </Typography>
                <Typography variant='h3'>
                  {dayjs(date).format('MMMM, YYYY')}
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <EventRoundedIcon color='info' sx={{ fontSize: 48 }} />
              </Stack>
            </Stack>
            <Typography variant='textLabel'>
              Next payout date:
              {' '}
              {dayjs(payoutDate).format('LL')}
            </Typography>
          </Stack>
        </CardActionArea>
      </Card>
      <ExplainModal
        close={() => setExplain(false)}
        color={themeItem.palette.info.main}
        description='Releese pays out royalties on the 25th day of every month. Income is reported 2 months
        after the end of the month in which income has taken place. For example, July earnings are posted on
        September 25th.'
        icon={<EventRoundedIcon
          sx={{
            color: theme => theme.palette.primary.contrastText,
            fontSize: '64px',
          }}
        />}
        open={explain}
        title='Next payout'
      />
    </Stack>
  )
}

export default EarningsDistributionNextPayout

