import CloseIcon from '@mui/icons-material/Close'
import { Avatar, Button, Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

const ImportStartedModal = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  return (
    <Dialog
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      fullScreen={fullScreen}
      fullWidth
      maxWidth='sm'
      onClose={props.close}
      open={props.open}
      sx={{
        justifyContent: 'center',
      }}
    >
      <Stack paddingX={3} paddingY={3} width={1}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
        >
          <Typography variant='h3'>
            Import Releases
          </Typography>
          <IconButton onClick={() => props.close()}>
            <CloseIcon color='disabled' />
          </IconButton>
        </Stack>
        <Typography color='textSecondary' variant='body1'>
          We have started importing your catalog, we&apos;ll send you an email when it is complete.
        </Typography>
        <Stack alignItems='center' justifyContent='center' paddingTop={1} spacing={1} width={1}>
          <Avatar
            src={currentOrganisation?.logoUrl}
            sx={{
              backgroundColor: theme => theme.palette.background.elevatedCard,
              width: 160,
              height: 160,
            }}
          />
          <Typography lineHeight={1.2} variant='h4'>
            {currentOrganisation?.name}
          </Typography>
        </Stack>
        <Stack alignItems='flex-end' justifyContent='flex-end' width={1}>
          <Button
            onClick={() => props.close()}
            sx={{
              width: 'fit-content',
              flexShrink: 0,
            }}
            type='submit'
            variant='contained'
          >
            Complete
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default ImportStartedModal
