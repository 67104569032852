/* eslint-disable sonarjs/no-identical-functions */
import SearchIcon from '@mui/icons-material/SearchRounded'
import { CircularProgress, Grid, InputAdornment, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { getGlobalSearch } from 'src/api/analyticsCloud'
import SearchField from 'src/components/form-elements/SearchField'
import SearchArtistItem from 'src/components/pages/Audience/Components/Search/SearchArtist'
import ViralArtistItem from 'src/components/pages/Audience/Components/Viral Artists/ViralArtistItem'
import type { ArtistItemSingle } from 'src/models/AnalyticsCloud'
import type { SearchObject } from 'src/models/Distribution'

type Props = {
  viralArtists: ArtistItemSingle[]
  loading: boolean
}

const AnalyticsSearchPage = (props: Props) => {
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const topArtists = props.viralArtists

  const [loading, setLoading] = useState(false)
  const [searchItems, setSearchItems] = useState<SearchObject[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const searchArtists = searchItems.filter(item => item.type === 'Artist')
  const searchTracks = searchItems.filter(item => item.type === 'Track')

  useEffect(() => {
    if (searchQuery && searchQuery.length > 0) {
      void searchArtistFunction(searchQuery)
    } else {
      setSearchItems([])
    }
  }, [searchQuery])

  const searchArtistFunction = async (query: string) => {
    setLoading(true)
    await getGlobalSearch(query)
      .then(response => setSearchItems(response))
      .catch(() => null)
      .finally(() => setLoading(false))
  }

  return (
    <Stack alignItems='center' id='analytics-container' width={1}>
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction={mobile ? 'column' : 'row'}
        maxWidth={1332}
        minWidth={0}
        padding={0}
        width={1}
      >
        <Stack
          direction='column'
          minWidth={0}
          padding={0}
          spacing={2}
          width={1}
        >
          <Stack
            alignItems='center'
            height={1}
            overflow='auto'
            spacing={2}
            width={1}
          >
            <Stack width={1}>
              <Typography lineHeight={1.2} textAlign='center' variant='h2'>
                Search
              </Typography>
              <Typography color='text.secondary' textAlign='center' variant='body1'>
                Discover new artists and tracks
              </Typography>
            </Stack>
            <Stack alignItems='center' justifyContent='center' spacing={1} width={1}>
              <SearchField
                InputProps={{
                  endAdornment: loading && <InputAdornment position='end'>
                    <CircularProgress
                      sx={{ width: '14px!important', height: '14px!important' }}
                      variant='indeterminate'
                    />
                  </InputAdornment>
                  ,
                  startAdornment: <InputAdornment position='start'>
                    <SearchIcon sx={{ color: 'action.active' }} />
                  </InputAdornment>
                  ,
                }}
                autoComplete='off'
                onChange={event => setSearchQuery(event.target.value)}
                sx={{
                  maxWidth: 600,
                  width: 1,
                  height: '48px',
                  '.MuiInputBase-root': {
                    height: '48px',
                    borderRadius: '32px',
                  },
                }}
                value={searchQuery}
              />
            </Stack>
          </Stack>
          {searchQuery.length > 0 &&
            <Stack spacing={4} width={1}>
              {searchArtists.length > 0 &&
              <Stack spacing={2} width={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Typography lineHeight={1} variant='h3'>
                    Artists
                  </Typography>
                </Stack>
                <Stack width={1}>
                  <Grid container spacing={1.5}>
                    {searchArtists.map(artist =>
                      <SearchArtistItem
                        artistItem={artist}
                        key={artist.id}
                      />)}
                  </Grid>
                </Stack>
              </Stack>}
              {searchTracks.length > 0 &&
              <Stack spacing={2} width={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2} width={1}>
                  <Typography lineHeight={1} variant='h3'>
                    Songs
                  </Typography>
                </Stack>
                <Stack width={1}>
                  <Grid container spacing={1.5}>
                    {searchTracks.map(artist =>
                      <SearchArtistItem
                        artistItem={artist}
                        key={artist.id}
                      />)}
                  </Grid>
                </Stack>
              </Stack>}
            </Stack>}
          {searchQuery.length === 0 &&
          <Stack spacing={2} width={1}>
            <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2} width={1}>
              <Typography lineHeight={1} variant='h3'>
                Trending Artists
              </Typography>
            </Stack>
            {props.loading
              ? <Stack width={1}>
                <Stack
                  alignItems='center'
                  direction='column'
                  flexDirection='column'
                  width={1}
                >
                  {Array.from({ length: 20 }, () =>
                    <Skeleton
                      sx={{
                        width: '100%',
                        height: 32,
                        transform: 'none',
                        marginBottom: 2,
                        marginRight: 0.5,
                      }}
                    />)}
                </Stack>
              </Stack>
              : <Stack width={1}>
                <Grid container spacing={1.5}>
                  {topArtists.map(artist =>
                    <ViralArtistItem
                      artistItem={artist}
                      key={artist.id}
                    />)}
                </Grid>
              </Stack>}
          </Stack>}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AnalyticsSearchPage
