import CloseIcon from '@mui/icons-material/Close'
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded'
import { LoadingButton } from '@mui/lab'
import { Avatar, Button, Dialog, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { importReleaseFunction } from 'src/api/projects'
import ImportStartedModal from 'src/components/pages/Projects/EditProject/ImportStarted'
import { useAuth } from 'src/components/providers/AuthProvider'

type Props = {
  readonly close: () => void
  readonly open: boolean
}

// eslint-disable-next-line max-len
const spotifyRegex = /(https?:\/\/open.spotify.com\/(track|user|artist|album|playlist)\/[\dA-Za-z]+(\/playlist\/[\dA-Za-z]+|)|spotify:(track|user|artist|album|playlist):[\dA-Za-z]+(:playlist:[\dA-Za-z]+|))/

const ImportReleasesModal = (props: Props) => {
  const themeItem = useTheme()
  const fullScreen = useMediaQuery(themeItem.breakpoints.down('md'))

  const { currentOrganisation, refreshCurrentOrganisation } = useAuth()
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [importUrl, setImportUrl] = useState('')

  const handleSubmit = () => {
    setLoading(true)
    const executed = spotifyRegex.exec(importUrl)
    const type = executed?.[2]
    const url = executed?.[0]

    if (type && url) {
      const id = url.replace(`https://open.spotify.com/${type}/`, '')
      if (id.length > 0 && currentOrganisation) {
        // Execute API call
        void importReleaseFunction(type, id, currentOrganisation.id)
          .then(() => void refreshCurrentOrganisation())
          .then(() => setDone(true))
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentOrganisation?.analyticsArtistId && currentOrganisation.analyticsArtistId.length > 0) {
      setImportUrl(`https://open.spotify.com/artist/${currentOrganisation.analyticsArtistId}`)
    }
  }, [currentOrganisation?.analyticsArtistId])

  return (
    <>
      <Dialog
        BackdropProps={{
          timeout: 500,
        }}
        closeAfterTransition
        fullScreen={fullScreen}
        fullWidth
        maxWidth='sm'
        onClose={props.close}
        open={props.open}
        sx={{
          justifyContent: 'center',
        }}
      >
        <Stack paddingX={3} paddingY={3} width={1}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
          >
            <Typography variant='h3'>
              Import Releases
            </Typography>
            <IconButton onClick={() => props.close()}>
              <CloseIcon color='disabled' />
            </IconButton>
          </Stack>
          <Typography color='textSecondary' variant='body1'>
            Import all releases from an artist, playlist, or an individual song/album into your dahsboard.
            Releese will map all metadata and create Smart Links for every release.
          </Typography>
          <Stack alignItems='center' justifyContent='center' paddingTop={1} spacing={1} width={1}>
            <Avatar
              src={currentOrganisation?.logoUrl}
              sx={{
                backgroundColor: theme => theme.palette.background.elevatedCard,
                width: 160,
                height: 160,
              }}
            />
            <Typography lineHeight={1.2} variant='h4'>
              {currentOrganisation?.name}
            </Typography>
          </Stack>
          <Stack width={1}>
            <form
              autoComplete='off'
              onSubmit={event => {
                event.preventDefault()
                handleSubmit()
              }}
            >
              <TextField
                autoComplete='new-password'
                fullWidth
                inputProps={{
                  autocomplete: 'off',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={event_ => setImportUrl(event_.target.value)}
                placeholder='https://open.spotify.com/artist/a1b2c3d4e5f6g7h'
                value={importUrl}
              />
              <Stack direction='row' justifyContent='flex-end' marginTop={4} spacing={1} width={1}>
                <Stack direction='row' spacing={1}>
                  <Button
                    onClick={() => props.close()}
                    sx={{
                      width: 'fit-content',
                      flexShrink: 0,
                    }}
                    variant='text'
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={loading || done}
                    endIcon={<CloudDownloadRoundedIcon />}
                    loading={loading}
                    sx={{
                      width: 'fit-content',
                      flexShrink: 0,
                    }}
                    type='submit'
                    variant='contained'
                  >
                    Import Releases
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Stack>
      </Dialog>
      <ImportStartedModal
        close={() => {
          setDone(false)
          props.close()
        }}
        open={done}
      />
    </>
  )
}

export default ImportReleasesModal
