/* eslint-disable sonarjs/no-duplicate-string */
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Card, Grid, IconButton, Stack, Tab, Typography, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import Markdown from 'react-markdown'
import { useLocation } from 'react-router-dom'
import WorldMap from 'react-svg-worldmap'
import { v4 } from 'uuid'

import { useContainerDimensions } from 'src/components/ContainerDimensions'
import UpdateReleaseModal from 'src/components/pages/Projects/UpdateModal/UpdateReleaseModal'
import ViewDistributionSupport from 'src/components/pages/Projects/ViewProject/ViewProjectTabs/DistributionTab/ViewDistributionSupport'
import { GetColorLink } from 'src/components/pages/SmartLink/Content/GetColorLink'
import GetIcon from 'src/components/pages/SmartLink/Content/GetIcon'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { Distribution } from 'src/models/Distribution'
import type Project from 'src/models/Project'

type Props = {
  readonly distributionItem: Distribution | undefined
  readonly project: Project
}

const ViewDistribution = (props: Props) => {
  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState('distribution')
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)
  const [metadataModalOpen, setMetadataModalOpen] = useState(false)

  const territoryList = props.distributionItem?.territories.map(({ iso }) =>
    ({ country: iso.toUpperCase(), value: 1 })) ?? []

  const search = useLocation().search
  const goto = new URLSearchParams(search).get('tab')

  const moderationMessage = props.distributionItem?.moderation.filter(item => item.from === 'moderation').pop()

  useEffect(() => {
    if (goto === 'support') {
      setCurrentTab('support')
    }
  }, [goto])

  return (
    <>
      {metadataModalOpen && props.distributionItem &&
      <UpdateReleaseModal
        close={() => setMetadataModalOpen(false)}
        distribution={props.distributionItem}
        open={metadataModalOpen}
        project={props.project}
      />}
      <Stack height={1} width={1}>
        <TabContext value={currentTab}>
          <Stack direction='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              aria-label='Current projects tab'
              onChange={(event, value: string) => setCurrentTab(value)}
            >
              <Tab
                icon={<PublishRoundedIcon />}
                label='Overview'
                value='distribution'
              />
              <Tab
                disabled={currentOrganisation?.id !== props.project.organisation?.id ||
                props.distributionItem?.status !== 'Distributed'}
                icon={<HelpRoundedIcon />}
                label='Support'
                value='support'
              />
            </TabList>
          </Stack>
          <TabPanel sx={{ width: 1, height: 1, padding: 0, overflow: 'auto' }} value='distribution'>
            <Stack marginX='auto' maxWidth={900} paddingBottom={4} paddingTop={2} paddingX={2} spacing={1} width={1}>

              {props.distributionItem?.status === 'Changes requested' &&
               props.project.organisation?.id === currentOrganisation?.id &&
               <Stack paddingBottom={2} spacing={1} width={1}>
                 <Stack
                   alignItems='flex-start'
                   direction='row'
                   justifyContent='space-between'
                   width={1}
                 >
                   <Typography lineHeight={1.2} variant='h3'>
                     Changes Requested
                     {' '}
                     <WarningRoundedIcon
                       color='warning'
                       sx={{
                         marginBottom: '4px!important',
                       }}
                     />
                   </Typography>
                 </Stack>
                 <Stack direction='column' spacing={2} width={1}>
                   {/* Display latest moderation messages */}
                   {moderationMessage &&
                   <Stack width={1}>
                     <Card
                       elevation={0}
                       sx={{
                         background: theme.palette.background.elevatedCard,
                       }}
                     >
                       <Stack padding={2} width={1}>
                         <Typography
                           sx={{
                             '& > *': {
                               marginBottom: 1,
                               marginTop: 0,
                             },
                           }}
                           variant='body1'
                         >
                           <Markdown>
                             {moderationMessage.message}
                           </Markdown>
                         </Typography>
                       </Stack>
                     </Card>
                   </Stack>}
                   <Button
                     color='warning'
                     endIcon={<ConstructionRoundedIcon />}
                     onClick={() => setMetadataModalOpen(true)}
                     sx={{
                       width: 'fit-content',
                     }}
                   >
                     Fix Release
                   </Button>
                 </Stack>
               </Stack>}

              <Typography variant='h3'>
                Territories
              </Typography>
              <Stack alignItems='center' ref={componentRef} sx={{ pointerEvents: 'none' }} width={1}>
                {props.distributionItem &&
                <WorldMap
                  backgroundColor='transparent'
                  borderColor={theme.palette.divider}
                  color={theme.palette.primary.main}
                  data={territoryList}
                  size={width > 800 ? width / 1.2 : width}
                  strokeOpacity={1}
                  valueSuffix=''
                />}
              </Stack>
              <Typography variant='h3'>
                Stores
              </Typography>
              <Grid container spacing={2} width={1}>
                {props.distributionItem?.platforms.map(platform =>
                  <>
                    <Grid item key={`${platform.name}-${v4()}`} lg={4} md={4} sm={6} xl={3} xs={6}>
                      <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                        <IconButton
                          disableRipple
                          sx={{
                            backgroundColor: GetColorLink(platform.name),
                            color: 'white!important',
                            ':hover': {
                              backgroundColor: GetColorLink(platform.name),
                              cursor: 'default',
                            },
                            '& .MuiSvgIcon-root': {
                              color: 'white!important',
                            },
                          }}
                        >
                          {GetIcon(platform.name)}
                        </IconButton>
                        <Typography variant='body1'>
                          {platform.name}
                        </Typography>
                      </Stack>
                    </Grid>
                    {platform.name === 'Facebook' &&
                    <Grid item key={`Instagram-${v4()}`} lg={4} md={4} sm={6} xl={3} xs={6}>
                      <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                        <IconButton
                          disableRipple
                          sx={{
                            backgroundColor: GetColorLink('Instagram'),
                            color: 'white!important',
                            ':hover': {
                              backgroundColor: GetColorLink('Instagram'),
                              cursor: 'default',
                            },
                            '& .MuiSvgIcon-root': {
                              color: 'white!important',
                            },
                          }}
                        >
                          {GetIcon('Instagram')}
                        </IconButton>
                        <Typography variant='body1'>
                          Instagram
                        </Typography>
                      </Stack>
                    </Grid>}
                    {platform.name === 'iTunes & Apple Music' &&
                    <Grid item key={`Shazam-${v4()}`} lg={4} md={4} sm={6} xl={3} xs={6}>
                      <Stack alignItems='center' direction='row' justifyContent='flex-start' spacing={1} width={1}>
                        <IconButton
                          disableRipple
                          sx={{
                            backgroundColor: GetColorLink('Shazam'),
                            color: 'white!important',
                            ':hover': {
                              backgroundColor: GetColorLink('Shazam'),
                              cursor: 'default',
                            },
                            '& .MuiSvgIcon-root': {
                              color: 'white!important',
                            },
                          }}
                        >
                          {GetIcon('Shazam')}
                        </IconButton>
                        <Typography variant='body1'>
                          Shazam
                        </Typography>
                      </Stack>
                    </Grid>}
                  </>)}
              </Grid>
            </Stack>
          </TabPanel>
          {currentOrganisation?.id === props.project.organisation?.id &&
          <TabPanel sx={{ width: 1, height: 1, padding: 0, overflow: 'auto' }} value='support'>
            <ViewDistributionSupport
              distributionItem={props.distributionItem}
              project={props.project}
            />
          </TabPanel>}
        </TabContext>
      </Stack>
    </>
  )
}

export default ViewDistribution
