/* eslint-disable max-len */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable unicorn/better-regex */
/* eslint-disable no-duplicate-imports */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { LoadingButton } from '@mui/lab'
import {
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useState } from 'react'

import { verifyEmail } from 'src/api/accounts'
import { useAuth } from 'src/components/providers/AuthProvider'
import type Account from 'src/models/Account'
import type { NewAccount } from 'src/models/Account'

type Props = {
  readonly account: NewAccount
  readonly onSave: (newAccount: Account) => Promise<void>
}

const checkEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const PersonalInformation = (props: Props) => {
  const { updateCurrentAccount, currentFirebaseUser } = useAuth()
  const themeColors = useTheme()
  const matches = useMediaQuery(themeColors.breakpoints.down('md'))

  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState(currentFirebaseUser.email ?? '')
  const [firstName, setFirstName] = useState(props.account.firstName)
  const [lastName, setLastName] = useState(props.account.lastName)

  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      if (noEmailAssociatedWithAccount && checkEmail.test(email)) {
        await currentFirebaseUser.updateEmail(email)
          .then(() => {
            void verifyEmail(email)
          })
      }
      const newAccount: NewAccount = {
        ...props.account,
        email,
        firstName,
        lastName,
        discountCode: window.Rewardful?.coupon?.id ?? undefined,
      }

      await updateCurrentAccount(newAccount)
        .then(async account => {
          await props.onSave(account)
        })
        .catch(() => setLoading(false))
    } catch {
      setLoading(false)
    }
  }

  const noEmailAssociatedWithAccount = !currentFirebaseUser.email || currentFirebaseUser.email.length === 0

  return (
    <Stack
      justifyContent='center'
      maxWidth='md'
      sx={{
        border: 'none',
        width: 1,
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault()
          void handleFormSubmit()
        }}
      >
        <Stack direction='column' spacing={8} width={1}>
          <Stack direction='column'>
            <Typography variant='h2'>
              Welcome to Releese!
            </Typography>
            <Typography color={themeColors.palette.text.secondary} variant='body1'>
              {!noEmailAssociatedWithAccount
                ? `You are signing up as ${currentFirebaseUser.email}`
                : 'You are signing up with a Facebook account'}
            </Typography>
          </Stack>
          <Stack direction='column' spacing={8} width={1}>
            <Stack direction={matches ? 'column' : 'row'} spacing={matches ? 4 : 2}>
              {noEmailAssociatedWithAccount &&
              <TextField
                autoComplete='new-password'
                error={email.length > 0 && !checkEmail.test(email)}
                fullWidth
                id='email'
                label='Email address'
                onChange={event => setEmail(event.target.value)}
                placeholder='jane@doe.com'
                sx={{
                  marginTop: 0,
                  fontSize: '1rem',
                  '& .MuiInputBase-root': {
                    height: 48,
                    fontSize: '1.25rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '1.25rem',
                  },
                }}
                value={email}
              />}
              <TextField
                autoComplete='new-password'
                fullWidth
                id='firstname'
                label='First name'
                onChange={event => setFirstName(event.target.value)}
                placeholder='Jane'
                sx={{
                  marginTop: 0,
                  fontSize: '1rem',
                  '& .MuiInputBase-root': {
                    height: 48,
                    fontSize: '1.25rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '1.25rem',
                  },
                }}
                value={firstName}
              />
              <TextField
                autoComplete='new-password'
                fullWidth
                id='lastname'
                label='Last name'
                onChange={event => setLastName(event.target.value)}
                placeholder='Doe'
                sx={{
                  marginTop: 0,
                  fontSize: '1rem',
                  '& .MuiInputBase-root': {
                    height: 48,
                    fontSize: '1.25rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '1.25rem',
                  },
                }}
                value={lastName}
              />
            </Stack>
            <Stack marginLeft='auto!important' width='fit-content'>
              <LoadingButton
                disabled={
                  !firstName || !lastName || email.length === 0 || !checkEmail.test(email)
                }
                endIcon={<ArrowForwardIosRoundedIcon />}
                loading={loading}
                sx={{
                  width: 'fit-content',
                }}
                type='submit'
                variant='contained'
              >
                Continue
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export default PersonalInformation
