import { Avatar, Paper, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import Markdown from 'react-markdown'

import LogoR from 'src/images/LogoR.svg'
import type { ModerationRequest } from 'src/models/Distribution'

type Props = {
  readonly moderationMessage: ModerationRequest
}

const ModerationMessageItem = (props: Props) =>
  <Stack
    alignItems={props.moderationMessage.from === 'user'
      ? 'flex-end'
      : 'flex-start'}
    width={1}
  >
    <Stack
      alignItems='flex-end'
      direction='row'
      maxWidth={436}
      spacing={1}
    >
      {props.moderationMessage.from !== 'user' &&
      <Stack>
        <Avatar
          imgProps={{
            sx: {
              width: '28px',
              height: '28px',
            },
          }}
          src={LogoR}
          sx={{
            width: 42,
            height: 42,
            marginBottom: '22px',
            background: 'white',
          }}
        />
      </Stack>}
      <Stack>
        <Paper
          elevation={0}
          sx={{
            maxWidth: 436,
            paddingX: 1.5,
            paddingTop: 1.5,
            paddingBottom: 0.5,
            backgroundColor: theme => props.moderationMessage.from === 'user'
              ? theme.palette.primary.main
              : theme.palette.background.input,
          }}
        >
          <Typography
            sx={{
              '& > *': {
                marginBottom: 1,
                marginTop: 0,
              },
            }}
          >
            <Markdown>
              {props.moderationMessage.message}
            </Markdown>
          </Typography>
          {' '}
        </Paper>
        <Typography
          color='textLabel'
          sx={{ paddingLeft: 1.5, paddingTop: '4px' }}
          variant='body3'
        >
          {dayjs(props.moderationMessage.timestamp).format('lll')}
        </Typography>
      </Stack>
    </Stack>
  </Stack>

export default ModerationMessageItem
