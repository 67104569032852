/* eslint-disable max-lines */
/* eslint-disable unicorn/no-unsafe-regex */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable sonarjs/no-identical-functions */
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded'
import PhonelinkRoundedIcon from '@mui/icons-material/PhonelinkRounded'
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded'
import { Alert, AlertTitle, Button, Card, Chip, FormControlLabel, Stack, Switch, TextField, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import type { ColorResult } from 'react-color'

import DomainNameSelectField from 'src/components/form-elements/DomainNameSelectField'
import SearchArtistComponent from 'src/components/form-elements/SearchArtists'
import SpotifyIcon from 'src/components/Icons/SpotifyIcon'
import BillingModal from 'src/components/pages/Account/Billing/BillingModal'
import EditLinkBackgroundColor from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkBackground'
import EditLinkButtonColors from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkButtonColors'
import EditLinkButtonShape from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkButtonShape'
import EditLinkFont from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkFont'
import EditLinkPalette from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkPalette'
import EditLinkTemplate from 'src/components/pages/Links/EditLink/EditLinkComponents/EditLinkTheme'
import MarketingSettingsModal from 'src/components/pages/Links/LinksList/LinksListModal/MarketingCloudSettingsModal'
import PreSaveSocials from 'src/components/pages/Projects/DistributeModal/DistributeModalComponents/PreSaveSocials'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { ArtistObject } from 'src/models/Distribution'
import type { SocialList } from 'src/models/Marketing'

const checkCname = /(?:[\dA-Za-z]{2,61}\.){2}[\dA-Za-z]{2,61}/

type Props = {
  readonly subdomain: string
  readonly setSubdomain: React.Dispatch<React.SetStateAction<string>>
  readonly domain: string
  readonly setDomain: React.Dispatch<React.SetStateAction<string>>
  readonly url: string
  readonly setUrl: React.Dispatch<React.SetStateAction<string>>
  readonly bgColor: string
  readonly setBgColor: React.Dispatch<React.SetStateAction<string>>
  readonly theme: string
  readonly setTheme: React.Dispatch<React.SetStateAction<string>>
  readonly palette: string
  readonly setPalette: React.Dispatch<React.SetStateAction<string>>
  readonly subscribe: boolean
  readonly setSubscribe: React.Dispatch<React.SetStateAction<boolean>>
  readonly buttonStyle: string
  readonly setButtonStyle: React.Dispatch<React.SetStateAction<string>>
  readonly buttonShape: string
  readonly setButtonShape: React.Dispatch<React.SetStateAction<string>>
  readonly presaveFont: string
  readonly setPresaveFont: React.Dispatch<React.SetStateAction<string>>
  readonly showAllReleases: boolean
  readonly setShowAllReleases: React.Dispatch<React.SetStateAction<boolean>>
  readonly presaveActive: boolean
  readonly setPresaveActive: React.Dispatch<React.SetStateAction<boolean>>
  readonly presaveFollowArtists: ArtistObject[]
  readonly setPresaveFollowArtists: React.Dispatch<React.SetStateAction<ArtistObject[]>>

  readonly description: string
  readonly setDescription: React.Dispatch<React.SetStateAction<string>>

  readonly setPresaveDefaultSocialIcons: React.Dispatch<React.SetStateAction<boolean>>
  readonly presaveDefaultSocialIcons: boolean
  readonly socialList: SocialList[]
  readonly setSocialList: React.Dispatch<React.SetStateAction<SocialList[]>>

  readonly colors: string[]
  readonly missingSubdomain: boolean
  readonly missingUrl: boolean
  readonly uniqueLinkBoolean: boolean

  readonly artworkPreviewUrl: string
}

const PreSaveAddOn = (props: Props) => {
  const themeItem = useTheme()
  const { currentOrganisation } = useAuth()
  const [billingModal, setBillingModalOpen] = useState(false)
  const [settings, setSettings] = useState(false)

  const notRightTier =
    currentOrganisation?.membershipTier !== 'CREATOR_PRO' &&
    currentOrganisation?.membershipTier !== 'CREATOR_PRO_YEARLY' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE' &&
    currentOrganisation?.membershipTier !== 'ENTERPRISE_YEARLY'

  const handleColorChange = (colorItem: ColorResult) => {
    props.setBgColor(colorItem.hex)
  }

  const domainsList = currentOrganisation?.customDomainSubmitted
    ? [ 'rls.ee', 'trck.ee', currentOrganisation.customDomain ]
    : [ 'rls.ee', 'trck.ee' ]

  const handleSubdomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const linkName =
      event.target.value.replace(/\s/g, '').replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\-]/g, '').toLowerCase()
    props.setSubdomain(linkName)
  }

  const handleDomainChange = (service: string) => {
    props.setDomain(service)
  }

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const linkName =
      event.target.value.replace(/\s/g, '').replace(/[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/g, '').toLowerCase()
    props.setUrl(linkName)
  }

  return (
    <>
      {billingModal && currentOrganisation &&
      <BillingModal
        account={currentOrganisation}
        close={() => setBillingModalOpen(false)}
        open={billingModal}
      />}
      <MarketingSettingsModal
        close={() => setSettings(false)}
        open={settings}
      />
      <Stack direction='column' maxWidth={768}>
        <Stack direction='column' spacing={3}>
          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Subscriptions
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} spacing={2} width={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
                  <Stack direction='column'>
                    <Typography lineHeight={1.4} variant='h4'>
                      Let your fans subscribe
                    </Typography>
                    <Typography color='text.secondary' variant='body1'>
                      Add a button so your visitors can subscribe to your Releese profile
                    </Typography>
                  </Stack>
                  <Stack alignItems='center' direction='row' spacing={2}>
                    <Switch
                      checked={props.subscribe}
                      disabled={currentOrganisation?.membershipTier === 'FREE' ||
                        currentOrganisation?.membershipTier === 'STARTER' ||
                        currentOrganisation?.membershipTier === 'STARTER_YEARLY'}
                      onChange={(_event, checked) => props.setSubscribe(checked)}
                    />
                  </Stack>
                </Stack>
                <Alert
                  icon={false}
                  severity='success'
                  sx={{
                    color: 'white!important',
                  }}
                  variant='filled'
                >
                  <AlertTitle
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    <NotificationsRoundedIcon />
                    {' Grow your fanbase'}
                  </AlertTitle>
                  When your listeners subscribe to your Releese profile, they will automatically save all your future
                  releases in their Spotify, Apple Music, or Deezer library.
                </Alert>
                {(currentOrganisation?.membershipTier === 'FREE' ||
                  currentOrganisation?.membershipTier === 'STARTER' ||
                  currentOrganisation?.membershipTier === 'STARTER_YEARLY') &&
                  <Button
                    onClick={() => setBillingModalOpen(true)}
                    startIcon={<AutoAwesomeRoundedIcon />}
                    sx={{
                      width: 'fit-content',
                      flexShrink: 0,
                      color: 'white!important',
                      background: '#FFA500',
                      ':hover': {
                        background: '#c47f00',
                      },
                    }}
                  >
                    Upgrade
                  </Button>}
              </Stack>
            </Card>
          </Stack>

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              width={1}
            >
              <Typography variant='h3'>
                Follow on Spotify
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} spacing={2} width={1}>
                <Stack
                  width={1}
                >
                  <SearchArtistComponent
                    label='Artists to follow'
                    limit={5}
                    placeholder='Search for artists'
                    selectedArtists={props.presaveFollowArtists}
                    setSelectedArtists={value => props.setPresaveFollowArtists(value ?? [])}
                  />
                </Stack>
                <Alert
                  icon={false}
                  severity='success'
                  sx={{
                    color: 'white!important',
                    background: '#1ED760',
                  }}
                  variant='filled'
                >
                  <AlertTitle
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    <SpotifyIcon />
                    {' Gain Spotify Followers'}
                  </AlertTitle>
                  Whenever a user pre-saves your release on Spotify, they will follow all artists you set here.
                </Alert>
              </Stack>
            </Card>
          </Stack>

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              width={1}
            >
              <Typography variant='h3'>
                Display all releases
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} spacing={2} width={1}>
                <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
                  <Stack direction='column'>
                    <Typography lineHeight={1.4} variant='h4'>
                      Promote your releases
                    </Typography>
                    <Typography color='text.secondary' variant='body1'>
                      Add your catalog at the bottom of your link to gain more streams
                    </Typography>
                  </Stack>
                  <Stack alignItems='center' direction='row' spacing={2}>
                    <Switch
                      checked={props.showAllReleases}
                      disabled={currentOrganisation?.membershipTier === 'FREE'}
                      onChange={(_event, checked) => props.setShowAllReleases(checked)}
                    />
                  </Stack>
                </Stack>
                <Alert
                  icon={false}
                  severity='success'
                  sx={{
                    color: 'white!important',
                    background: 'black',
                  }}
                  variant='filled'
                >
                  <AlertTitle
                    sx={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    <ShowChartRoundedIcon sx={{ fontSize: '1.5rem' }} />
                    {' Keep your fans on your page'}
                  </AlertTitle>
                  Adding your releases at the bottom of your page can increase the engagement time on your link by over 75%,
                  driving more visitors to listen to your content.
                </Alert>
                {currentOrganisation?.membershipTier === 'FREE' &&
                <Button
                  onClick={() => setBillingModalOpen(true)}
                  startIcon={<AutoAwesomeRoundedIcon />}
                  sx={{
                    width: 'fit-content',
                    flexShrink: 0,
                    color: 'white!important',
                    background: '#FFA500',
                    ':hover': {
                      background: '#c47f00',
                    },
                  }}
                >
                  Upgrade
                </Button>}
              </Stack>
            </Card>
          </Stack>

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              width={1}
            >
              <Typography variant='h3'>
                Description
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} spacing={2} width={1}>
                <Stack direction='column'>
                  <Typography lineHeight={1.4} variant='h4'>
                    Personal message
                  </Typography>
                  <Typography color='text.secondary' variant='body1'>
                    Pre-save links with personal messages get 35% more clicks
                  </Typography>
                </Stack>
                <Stack direction='column' justifyContent='space-between' spacing={1}>
                  <TextField
                    autoComplete='new-password'
                    fullWidth
                    id='description'
                    inputProps={{ maxLength: 500 }}
                    label='Description'
                    minRows={4}
                    multiline
                    onChange={event => props.setDescription(event.target.value)}
                    placeholder='My new song is out soon...'
                    sx={{
                      marginTop: 4,
                    }}
                    value={props.description}
                  />
                </Stack>
              </Stack>
            </Card>
          </Stack>

          <Stack spacing={2} width={1}>
            <Stack direction='column' width={1}>
              <Stack
                alignItems='flex-start'
                direction='row'
                justifyContent='space-between'
                paddingTop={2}
                width={1}
              >
                <Typography variant='h3'>
                  Page style
                </Typography>
              </Stack>
              <Card
                variant='outlined'
              >
                <Stack padding={4} spacing={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Template
                      </Typography>
                      <EditLinkTemplate
                        artworkPreview={props.artworkPreviewUrl}
                        colors={props.colors}
                        template={props.theme}
                        updateTemplate={(template: string) => props.setTheme(template)}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Theme
                      </Typography>
                      <EditLinkPalette
                        activePalette={props.palette}
                        updateActivePalette={(palette: string) => props.setPalette(palette)}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Font
                      </Typography>
                      <EditLinkFont
                        font={props.presaveFont}
                        updateFont={(font: string) => props.setPresaveFont(font)}
                      />
                    </Stack>
                  </Stack>

                  {props.theme === 'Vibrant' &&
                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Background color
                      </Typography>
                      <EditLinkBackgroundColor
                        backgroundColor={props.bgColor}
                        colors={props.colors}
                        handleColorChange={handleColorChange}
                      />
                    </Stack>
                  </Stack>}
                </Stack>
              </Card>
            </Stack>
            <Stack direction='column' width={1}>
              <Stack
                alignItems='flex-start'
                direction='row'
                justifyContent='space-between'
                paddingTop={2}
                width={1}
              >
                <Typography variant='h3'>
                  Buttons
                </Typography>
              </Stack>
              <Card
                variant='outlined'
              >
                <Stack padding={4} spacing={2} width={1}>
                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Button palette
                      </Typography>
                      <EditLinkButtonColors
                        buttonColors={props.buttonStyle}
                        updateButtonColors={(colorStyle: string) => props.setButtonStyle(colorStyle)}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction='row' justifyContent='space-between' spacing={2} width={1}>
                    <Stack spacing={1} width={1}>
                      <Typography sx={{ marginTop: 0 }} variant='h4'>
                        Button shape
                      </Typography>
                      <EditLinkButtonShape
                        buttonShape={props.buttonShape}
                        updateButtonShape={(shape: string) => props.setButtonShape(shape)}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Stack>

          <Stack width={1}>
            <Stack
              alignItems='flex-start'
              direction='row'
              justifyContent='space-between'
              paddingTop={2}
              width={1}
            >
              <Typography variant='h3'>
                Destination
              </Typography>
            </Stack>
            <Card
              variant='outlined'
            >
              <Stack direction='column' padding={2} width={1}>
                <Stack>
                  <Stack>
                    <Typography lineHeight={1.4} variant='h4'>
                      Pick your link
                    </Typography>
                    <Typography color='text.secondary' variant='body1'>
                      Select your preferred subdomain, domain, and destination link. Using a custom domain removes
                      the Releese logo from your link.
                    </Typography>
                  </Stack>
                  <Stack spacing={1}>
                    <Stack alignItems='center' direction='row' justifyContent='space-between' paddingTop={4} spacing={2}>
                      <TextField
                        autoComplete='new-password'
                        disabled={checkCname.test(props.domain)}
                        error={props.missingSubdomain || !props.uniqueLinkBoolean}
                        fullWidth
                        id='subdomain'
                        label='Subdomain'
                        onChange={handleSubdomainChange}
                        placeholder='Subdomain'
                        sx={{ marginTop: 0 }}
                        value={props.subdomain}
                      />
                      <DomainNameSelectField
                        error={!props.uniqueLinkBoolean}
                        serviceName={props.domain}
                        services={domainsList}
                        setInputSelected={handleDomainChange}
                      />
                      <TextField
                        autoComplete='new-password'
                        error={props.missingUrl || !props.uniqueLinkBoolean}
                        fullWidth
                        id='url'
                        label='URL'
                        onChange={handleUrlChange}
                        placeholder='URL'
                        sx={{ marginTop: 0 }}
                        value={props.url}
                      />
                    </Stack>

                    {!props.uniqueLinkBoolean &&
                    <Typography color='error' variant='body2'>
                      This URL is already taken, please choose another
                    </Typography>}

                    <Stack paddingY={2} width={1}>
                      <Typography lineHeight={1.4} variant='h4'>
                        Visibility
                      </Typography>
                      <Typography color='text.secondary' variant='body1'>
                        Hide this Smart Link from your Bio Link and prevent it from being accessible by setting it to
                        inactive
                      </Typography>
                      <FormControlLabel
                        control={<Switch
                          checked={props.presaveActive}
                          onChange={event => props.setPresaveActive(event.target.checked)}
                          sx={{
                            marginRight: 1,
                          }}
                        />}
                        label='Active'
                        sx={{
                          marginRight: '0px!important',
                          marginLeft: '0px!important',
                          marginTop: 1,
                        }}
                      />
                    </Stack>

                    {!props.missingSubdomain && !props.missingUrl &&
                    <Stack width={1}>
                      <Typography color='text.label' variant='body2'>
                        Link preview
                      </Typography>
                      <Typography color={themeItem.palette.text.link} variant='body1'>
                        {String(props.subdomain).length > 0 &&
              String(props.domain).split('.').length === 2
              // eslint-disable-next-line max-len
                          ? `https://${String(props.subdomain)}.${String(props.domain)}/${String(props.url)}`
                          : `https://${String(props.domain)}/${String(props.url)}`}
                      </Typography>
                    </Stack>}
                  </Stack>
                  {!notRightTier &&
                  <Button
                    color='secondary'
                    onClick={() => setSettings(true)}
                    startIcon={<PhonelinkRoundedIcon />}
                    sx={{
                      width: 'fit-content',
                      marginTop: 1,
                    }}
                  >
                    Manage custom domain
                  </Button>}
                </Stack>
              </Stack>
            </Card>
          </Stack>

          {notRightTier &&
          <Alert icon={false} severity='info' sx={{ background: '#26252a' }} variant='filled'>
            <Stack>
              <Typography lineHeight={1.4} variant='h3'>
                {'Custom domain '}
                <Chip
                  color='success'
                  label='Pro+'
                  size='small'
                />
              </Typography>
              <Typography color='white' variant='body1'>
                Remove all Releese branding and bring your own domain instead of &quot;rls.ee&quot; and &quot;trck.ee&quot;.
              </Typography>
            </Stack>
            <Button
              onClick={() => setBillingModalOpen(true)}
              startIcon={<AutoAwesomeRoundedIcon />}
              sx={{
                width: 'fit-content',
                marginTop: 1,
                flexShrink: 0,
                color: 'white!important',
                background: '#FFA500',
                ':hover': {
                  background: '#c47f00',
                },
              }}
            >
              Upgrade
            </Button>
          </Alert>}

          <PreSaveSocials
            presaveDefaultSocialIcons={props.presaveDefaultSocialIcons}
            setPresaveDefaultSocialIcons={props.setPresaveDefaultSocialIcons}
            setSocialList={props.setSocialList}
            socialList={props.socialList}
          />
        </Stack>
      </Stack>
    </>
  )
}
export default PreSaveAddOn
