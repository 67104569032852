/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Card, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { createRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  getAnalyticsActivityArtist,
  getAnalyticsActivityArtistFree,
  getAnalyticsActivityLabel,
  getAnalyticsArtist,
  getAnalyticsArtistFree,
  getAnalyticsAudienceArtist,
  getAnalyticsAudienceArtistFree,
  getAnalyticsAudienceLabel,
  getAnalyticsCatalogArtist,
  getAnalyticsCatalogArtistFree,
  getAnalyticsCatalogLabel,
  getAnalyticsCurrentArtist,
  getAnalyticsCurrentArtistFree,
  getAnalyticsCurrentLabel,
  getAnalyticsLabel,
  getAnalyticsProfileArtist,
  getAnalyticsProfileArtistFree,
  getAnalyticsProfileLabel,
  getAnalyticsTopPlaylistsArtist,
  getAnalyticsTopPlaylistsArtistFree,
  getAnalyticsTopPlaylistsLabel,
  getLatestTracks,
  getLatestTracksTopGains,
  getTopArtists,
  getTopTracks,
  getViralArtists,
  getViralTracks,
} from 'src/api/analyticsCloud'
import AnalyticsStatement from 'src/components/pages/Analytics/AnalyticsTabs/AnalyticsStatement'
import ActivityRows from 'src/components/pages/Audience/Components/Activity/ActivityRows'
import AnalyticsProfileUi from 'src/components/pages/Audience/Components/Audience/AnalyticsCloudOverview'
import AnalyticsControlerAudience from 'src/components/pages/Audience/Components/Audience/AudienceControler'
import CatalogRows from 'src/components/pages/Audience/Components/Catalog/CatalogRows'
import LatestTracksCloud from 'src/components/pages/Audience/Components/Latest Tracks/LatestTracksSearch'
import AnalyticSearchNavMobile from 'src/components/pages/Audience/Components/Navigation/AnalyticsSearchMobileNav'
import AnalyticsSearchNav from 'src/components/pages/Audience/Components/Navigation/AnalyticsSearchNav'
import AnalyticsTotalOnboarding from 'src/components/pages/Audience/Components/Onboarding/AnalyticsTotalOnboarding'
import TopPlaylistsRows from 'src/components/pages/Audience/Components/Playlists/TopPlaylists'
import AnalyticsSearchPage from 'src/components/pages/Audience/Components/Search/AnalyticsSearchPage'
import TopArtistsCloud from 'src/components/pages/Audience/Components/Top Artists/TopArtistsCloud'
import TopTracksCloud from 'src/components/pages/Audience/Components/Top Tracks/TopTracksCloud'
import LinkAnalytics from 'src/components/pages/Links/Analytics/LinkAnalytics'
import LinkFans from 'src/components/pages/Links/Fans/LinkFans'
import UpgradeCTAButtons from 'src/components/pages/Menu/UpgradeCTA/UpgradeCTAButtons'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { AnalyticsActivity, AnalyticsAudienceData, AnalyticsCatalog, AnalyticsCurrentData, AnalyticsHistoricData, AnalyticsProfileData, TopPlaylists } from 'src/models/Analytics'
import type { ArtistItemSingle, NewMusicFridayTopGainersnalytics, NewMusicFridayTrackAnalytics, TopArtistAnalytics, TopTracksAnalytics, ViralTracksAnalytics } from 'src/models/AnalyticsCloud'

const AnalyticsSearch = () => {
  const { id } = useParams<{ id?: string }>()
  const themeItem = useTheme()
  const mobile = useMediaQuery(themeItem.breakpoints.down('md'))
  const { currentOrganisation } = useAuth()
  const [currentTab, setCurrentTab] = useState('')

  useEffect(() => {
    setCurrentTab(id ?? 'Overview')
  }, [id])

  const [loadingHistoric, setLoadingHistoric] = useState(false)
  const [loadingCurrent, setLoadingCurrent] = useState(false)
  const [loadingCatalog, setLoadingCatalog] = useState(false)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingAudience, setLoadingAudience] = useState(false)
  const [loadingPlaylists, setLoadingPlaylists] = useState(false)
  const [historicData, setHistoricData] = useState<AnalyticsHistoricData>()
  const [currentData, setCurrentData] = useState<AnalyticsCurrentData>()
  const [catalogData, setCatalogData] = useState<AnalyticsCatalog>()
  const [activityData, setActivityData] = useState<AnalyticsActivity>()
  const [profileData, setProfileData] = useState<AnalyticsProfileData>()
  const [audienceData, setAudienceData] = useState<AnalyticsAudienceData>()
  const [topPlaylistsData, setTopPlaylistsData] = useState<TopPlaylists>()

  const [loadingDiscovery, setLoadingDiscovery] = useState(false)
  const [topTracks, setTopTracks] = useState<TopTracksAnalytics[]>([])
  const [viralTracks, setViralTracks] = useState<ViralTracksAnalytics[]>([])
  const [topArtists, setTopArtists] = useState<TopArtistAnalytics[]>([])
  const [latestTracks, setLatestTracks] = useState<NewMusicFridayTrackAnalytics[]>([])
  const [topGains, setTopGains] = useState<NewMusicFridayTopGainersnalytics[]>([])
  const [viralArtists, setViralArtists] = useState<ArtistItemSingle[]>([])

  const refContainer = createRef<HTMLDivElement>()

  useEffect(() => {
    void getData()
    if (currentOrganisation?.membershipTier === 'FREE' ||
      currentOrganisation?.membershipTier === 'CREATOR' ||
      currentOrganisation?.membershipTier === 'CREATOR_YEARLY') {
      void getData()
    } else {
      void getDataPro()
    }
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.membershipTier !== 'FREE') {
      getMyData()
    } else if (currentOrganisation?.membershipTier === 'FREE') {
      getDataFree()
    }
  }, [currentOrganisation?.id, currentOrganisation?.analyticsOnboarded, currentOrganisation?.membershipTier])

  useEffect(() => {
    if (refContainer.current) {
      refContainer.current.scrollTo(0, 0)
    }
  }, [])

  const getData = async () => {
    setLoadingDiscovery(true)
    await Promise.all([
      getViralTracks()
        .then(items => setViralTracks(items)),
      getTopTracks()
        .then(items => setTopTracks(items)),
      getTopArtists()
        .then(items => setTopArtists(items)),
      getLatestTracks()
        .then(items => setLatestTracks(items)),
      getLatestTracksTopGains()
        .then(items => setTopGains(items)),
      getViralArtists()
        .then(items => setViralArtists(items)),
    ]).then(() => {
      setLoadingDiscovery(false)
    })
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  const getDataPro = async () => {
    setLoadingDiscovery(true)
    await Promise.all([
      getViralTracks()
        .then(items => setViralTracks(items)),
      getTopTracks()
        .then(items => setTopTracks(items)),
      getTopArtists()
        .then(items => setTopArtists(items)),
      getLatestTracks()
        .then(items => setLatestTracks(items)),
      getLatestTracksTopGains()
        .then(items => setTopGains(items)),
      getViralArtists()
        .then(items => setViralArtists(items)),
    ]).then(() => {
      setLoadingDiscovery(false)
    })
  }

  const setLoading = () => {
    setLoadingHistoric(true)
    setLoadingCurrent(true)
    setLoadingCatalog(true)
    setLoadingActivities(true)
    setLoadingProfile(true)
    setLoadingAudience(true)
    setLoadingPlaylists(true)
  }

  const getMyData = () => {
    setLoading()
    if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'artist' &&
      currentOrganisation.analyticsArtistId &&
      currentOrganisation.analyticsArtistId.length > 0) {
      void getAnalyticsArtist()
        .then(result => setHistoricData(result))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsCurrentArtist()
        .then(resultCurrent => setCurrentData(resultCurrent))
        .finally(() => setLoadingCurrent(false))
        .catch(() => setLoadingCurrent(false))
      void getAnalyticsCatalogArtist()
        .then(resultCurrent => setCatalogData(resultCurrent))
        .finally(() => setLoadingCatalog(false))
        .catch(() => setLoadingCatalog(false))
      void getAnalyticsActivityArtist()
        .then(result => setActivityData(result))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
      void getAnalyticsProfileArtist()
        .then(result => setProfileData(result))
        .finally(() => setLoadingProfile(false))
        .catch(() => setLoadingProfile(false))
      void getAnalyticsAudienceArtist()
        .then(result => setAudienceData(result))
        .finally(() => setLoadingAudience(false))
        .catch(() => setLoadingAudience(false))
      void getAnalyticsTopPlaylistsArtist()
        .then(result => setTopPlaylistsData(result))
        .finally(() => setLoadingPlaylists(false))
        .catch(() => setLoadingPlaylists(false))
    } else if (currentOrganisation?.analyticsOnboarded &&
      currentOrganisation.analyticsType === 'label' &&
      currentOrganisation.analyticsLabelId &&
      currentOrganisation.analyticsLabelId.length > 0) {
      void getAnalyticsLabel()
        .then(result => setHistoricData(result))
        .finally(() => setLoadingHistoric(false))
        .catch(() => setLoadingHistoric(false))
      void getAnalyticsCurrentLabel()
        .then(resultCurrent => setCurrentData(resultCurrent))
        .finally(() => setLoadingCurrent(false))
        .catch(() => setLoadingCurrent(false))
      void getAnalyticsCatalogLabel()
        .then(resultCurrent => setCatalogData(resultCurrent))
        .finally(() => setLoadingCatalog(false))
        .catch(() => setLoadingCatalog(false))
      void getAnalyticsActivityLabel()
        .then(result => setActivityData(result))
        .finally(() => setLoadingActivities(false))
        .catch(() => setLoadingActivities(false))
      void getAnalyticsProfileLabel()
        .then(result => setProfileData(result))
        .finally(() => setLoadingProfile(false))
        .catch(() => setLoadingProfile(false))
      void getAnalyticsAudienceLabel()
        .then(result => setAudienceData(result))
        .finally(() => setLoadingAudience(false))
        .catch(() => setLoadingAudience(false))
      void getAnalyticsTopPlaylistsLabel()
        .then(result => setTopPlaylistsData(result))
        .finally(() => setLoadingPlaylists(false))
        .catch(() => setLoadingPlaylists(false))
    }
  }

  const getDataFree = () => {
    setLoading()
    void getAnalyticsArtistFree()
      .then(result => setHistoricData(result))
      .finally(() => setLoadingHistoric(false))
      .catch(() => setLoadingHistoric(false))
    void getAnalyticsCurrentArtistFree()
      .then(resultCurrent => setCurrentData(resultCurrent))
      .finally(() => setLoadingCurrent(false))
      .catch(() => setLoadingCurrent(false))
    void getAnalyticsCatalogArtistFree()
      .then(resultCurrent => setCatalogData(resultCurrent))
      .finally(() => setLoadingCatalog(false))
      .catch(() => setLoadingCatalog(false))
    void getAnalyticsActivityArtistFree()
      .then(result => setActivityData(result))
      .finally(() => setLoadingActivities(false))
      .catch(() => setLoadingActivities(false))
    void getAnalyticsProfileArtistFree()
      .then(result => setProfileData(result))
      .finally(() => setLoadingProfile(false))
      .catch(() => setLoadingProfile(false))
    void getAnalyticsAudienceArtistFree()
      .then(result => setAudienceData(result))
      .finally(() => setLoadingAudience(false))
      .catch(() => setLoadingAudience(false))
    void getAnalyticsTopPlaylistsArtistFree()
      .then(result => setTopPlaylistsData(result))
      .finally(() => setLoadingPlaylists(false))
      .catch(() => setLoadingPlaylists(false))
  }

  const titlesSmartLinks = ['Engagement', 'Subscribers']
  const titlesDiscovery = ['Search', 'Songs', 'Artists', 'Latest']
  const titlesDistribution = ['Monthly Insights']
  const titlesMobile = ['Overview', 'Catalog', 'Audience', 'Playlists',
    ...titlesDistribution,
    ...titlesSmartLinks,
    ...titlesDiscovery]

  return (
    <Stack
      direction='row'
      height={1}
      id='analytics-container'
      justifyContent='center'
      overflow='auto'
    >
      <Stack
        alignItems='stretch'
        boxSizing='border-box'
        direction={mobile ? 'column' : 'row'}
        height={1}
        minWidth={0}
        padding={0}
        width={1}
      >
        {mobile
          ? <AnalyticSearchNavMobile
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            titles={titlesMobile}
          />
          : <>
            <AnalyticsSearchNav
              artistName={profileData?.profileData.name ?? ''}
              currentTab={currentTab}
              loading={loadingProfile}
              logoUrl={profileData?.profileData.avatar ?? ''}
              setCurrentTab={setCurrentTab}
              titlesDiscovery={titlesDiscovery}
              titlesDistribution={titlesDistribution}
              titlesSmartLinks={titlesSmartLinks}
            />
            <Divider
              orientation='vertical'
              sx={{
                height: 1,
              }}
            />
          </>}
        <Stack alignItems='center' height={1} overflow='auto' width={1}>
          {/* User audience */}
          {(currentTab.toLowerCase() === 'overview' ||
          currentTab.toLowerCase() === 'catalog' ||
          currentTab.toLowerCase() === 'audience' ||
          currentTab.toLowerCase() === 'playlists') &&
          <Stack alignItems='center' height={1} overflow='auto' padding={2} position='relative' width={1}>
            {currentOrganisation?.membershipTier === 'FREE' &&
            <Card
              elevation={2}
              sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                display: 'flex',
                zIndex: 999,
                background: 'transparent',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Stack maxWidth={400} spacing={2} width={1}>
                <Card
                  elevation={0}
                >
                  <List dense sx={{ width: 1 }}>
                    <ListItem dense disablePadding>
                      <Stack
                        alignItems='center'
                        direction='row'
                        paddingTop={1}
                        paddingX={3}
                        spacing={1}
                        width={1}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={currentOrganisation.logoUrl}
                            sx={{
                              height: 48,
                              width: 48,
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={currentOrganisation.name}
                          secondary='Your analytics'
                          sx={{
                            '.MuiListItemText-secondary': {
                              textTransform: 'capitalize',
                            },
                          }}
                        />
                      </Stack>
                    </ListItem>
                  </List>
                  <Stack
                    alignItems='center'
                    justifyContent='center'
                    paddingBottom={2}
                    paddingTop={1}
                    paddingX={2}
                    spacing={1}
                    width={1}
                  >
                    <UpgradeCTAButtons />
                    <Typography textAlign='center' variant='body2'>
                      Upgrade today to track your audience
                    </Typography>
                  </Stack>
                </Card>
              </Stack>
            </Card>}

            {currentOrganisation &&
            currentOrganisation.membershipTier !== 'FREE' &&
            !currentOrganisation.analyticsOnboarded
              ? <Stack alignItems='center' height={1} width={1}>
                <Stack
                  alignItems='stretch'
                  boxSizing='border-box'
                  direction='column'
                  height={1}
                  minWidth={0}
                  padding={0}
                  width={1}
                >
                  <Stack alignItems='center' height={1} overflow='auto' width={1}>
                    <AnalyticsTotalOnboarding />
                  </Stack>
                </Stack>
              </Stack>
              : <Stack
                alignItems='center'
                height={currentOrganisation
                  ? currentOrganisation.membershipTier === 'FREE'
                    ? 1
                    : undefined
                  : undefined}
                overflow={currentOrganisation
                  ? currentOrganisation.membershipTier === 'FREE'
                    ? 'hidden'
                    : undefined
                  : undefined}
                sx={{
                  filter: currentOrganisation
                    ? currentOrganisation.membershipTier === 'FREE'
                      ? 'blur(12px)'
                      : undefined
                    : undefined,
                  pointerEvents: currentOrganisation
                    ? currentOrganisation.membershipTier === 'FREE'
                      ? 'none'
                      : undefined
                    : undefined,
                }}
                width={1}
              >
                {currentTab.toLowerCase() === 'overview' &&
                <>
                  <AnalyticsProfileUi
                    currentData={currentData}
                    historicData={historicData}
                    loading={loadingHistoric || loadingProfile || loadingCurrent}
                    profileData={profileData}
                  />
                  <ActivityRows
                    chartItems={activityData?.data ?? []}
                    currentTab={currentTab}
                    loading={loadingActivities}
                    overview
                  />
                </>}
                {currentTab.toLowerCase() === 'catalog' &&
                <CatalogRows
                  chartItems={catalogData?.catalog ?? []}
                  loading={loadingCatalog}
                />}
                {currentTab.toLowerCase() === 'audience' &&
                <AnalyticsControlerAudience
                  activityData={activityData}
                  audienceData={audienceData}
                  currentData={currentData}
                  historicData={historicData}
                  loading={loadingAudience || loadingHistoric || loadingCurrent}
                />}
                {currentTab.toLowerCase() === 'playlists' &&
                <TopPlaylistsRows
                  loading={loadingPlaylists}
                  topPlaylistItems={topPlaylistsData}
                />}
              </Stack>}
          </Stack>}

          {/* Distribution */}
          {currentTab.toLowerCase().includes('monthly') &&
          <Stack alignItems='center' height={1} overflow='auto' width={1} >
            {currentTab.toLowerCase().includes('monthly') &&
            <AnalyticsStatement />}
          </Stack>}

          {/* Smart Links */}
          {(currentTab.toLowerCase() === 'engagement' ||
          currentTab.toLowerCase() === 'subscribers') &&
          <Stack alignItems='center' height={1} overflow='auto' width={1} >
            {currentTab.toLowerCase() === 'engagement' &&
            <LinkAnalytics />}
            {currentTab.toLowerCase() === 'subscribers' &&
            <LinkFans />}
          </Stack>}

          {/* Discovery */}
          {(currentTab.toLowerCase() === 'search' ||
          currentTab.toLowerCase() === 'songs' ||
          currentTab.toLowerCase() === 'artists' ||
          currentTab.toLowerCase() === 'latest' ||
          currentTab.toLowerCase() === 'recommended') &&
          <Stack alignItems='center' height={1} overflow='auto' padding={2} width={1} >
            {currentTab.toLowerCase() === 'search' &&
            <AnalyticsSearchPage
              loading={loadingDiscovery}
              viralArtists={viralArtists}
            />}
            {currentTab.toLowerCase() === 'songs' &&
            <TopTracksCloud
              loading={loadingDiscovery}
              topTracks={topTracks}
              viralTracks={viralTracks}
            />}
            {currentTab.toLowerCase() === 'artists' &&
            <TopArtistsCloud
              loading={loadingDiscovery}
              topArtists={topArtists}
            />}
            {currentTab.toLowerCase() === 'latest' &&
            <LatestTracksCloud
              latestTracks={latestTracks}
              loading={loadingDiscovery}
              topTracks={topGains}
            />}
          </Stack>}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default AnalyticsSearch
