/* eslint-disable react/no-unstable-nested-components */
import { Card, CardActionArea, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import Linkify from 'react-linkify'

import GetLink from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/GetLink'
import ImageView from 'src/components/pages/Messages/MessagesPanel/MessagesPanelComponents/ImageView'
import type { Message, MESSAGE_STATUS } from 'src/models/Message'

type MessageElementProps = {
  readonly message: Message
}

const SelfMessage = (props: MessageElementProps) => {
  const theme = useTheme()
  const [imageOpen, setImageOpen] = useState(false)

  const backgroundColor = (status: typeof MESSAGE_STATUS[number]) => {
    switch (status) {
      case 'SENT':
        return theme.palette.primary.light
      case 'ERROR':
        return theme.palette.error.main
      default:
        return theme.palette.primary.main
    }
  }

  return (
    <Stack
      flexDirection='row'
      justifyContent='flex-end'
      spacing={1}
    >
      <Stack>
        <Tooltip title={dayjs(props.message.createdAt).format('llll')}>

          {(/(jpg|gif|png|JPG|GIF|PNG|JPEG|jpeg)$/).test(props.message.content)
            ? <Card elevation={0}>
              <CardActionArea onClick={() => setImageOpen(true)}>
                <img
                  alt='Message'
                  src={props.message.content}
                  style={{ borderRadius: 8, boxShadow: 'none' }}
                  width={312}
                />
              </CardActionArea>
              <ImageView close={() => setImageOpen(false)} image={props.message.content} open={imageOpen} />
            </Card>
            : (/(https|http)/).test(props.message.content)
              ? <Stack>
                <Paper
                  elevation={0}
                  sx={{
                    maxWidth: 336,
                    padding: 1.5,
                    backgroundColor: backgroundColor(props.message.status),
                    color: `${theme.palette.primary.contrastText}!important`,
                  }}
                >
                  <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
                    <a href={decoratedHref} key={key} rel='noreferrer' style={{ color: 'inherit' }} target='blank'>
                      {decoratedText}
                    </a>}
                  >
                    <Typography sx={{ wordWrap: 'break-word' }}>
                      {props.message.content}
                    </Typography>
                  </Linkify>
                </Paper>
                {GetLink(props.message.content)}
              </Stack>
              : <Paper
                elevation={0}
                sx={{
                  maxWidth: 336,
                  padding: 1.5,
                  backgroundColor: backgroundColor(props.message.status),
                  color: `${theme.palette.primary.contrastText}!important`,
                }}
              >
                <Typography sx={{ wordWrap: 'break-word' }}>
                  {props.message.content}
                </Typography>
                {' '}
              </Paper>}
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default SelfMessage
