/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { Alert, Autocomplete, Chip, CircularProgress, Grid, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment-timezone'
import PQueue from 'p-queue'
import { useEffect, useState } from 'react'

import { getProjectTags, savePartialProject } from 'src/api/projects'
import SearchArtistComponentSubtle from 'src/components/form-elements/SearchArtistSubtle'
import SelectField from 'src/components/form-elements/SelectField'
import { upcValidation } from 'src/data/upcValidation'
import type { ArtistObject } from 'src/models/Distribution'
import type Project from 'src/models/Project'
import { PROJECT_STATUS } from 'src/models/Project'
import { GENRES, RECORDING_VERSIONS } from 'src/models/Recording'

function setColor(selected: string) {
  switch (selected) {
    case PROJECT_STATUS[0].toString():
      return 'success'
    case PROJECT_STATUS[1].toString():
      return 'warning'
    case PROJECT_STATUS[2].toString():
      return 'secondary'
    case PROJECT_STATUS[3].toString():
      return 'error'
    case PROJECT_STATUS[4].toString():
      return 'primary'
    default:
      return 'primary'
  }
}

type Props = {
  readonly project: Project
  readonly updatedFields: (project: Project) => void
  readonly distributing?: boolean
  readonly setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

const EditProjectDetailsComponent = (props: Props) => {
  const queue = new PQueue({ concurrency: 1 })
  const [projectFields, setProjectFields] = useState(props.project)
  const [savedToast, setSavedToastOpen] = useState(false)
  const validUpcCode = upcValidation(projectFields.UPC ? Number(projectFields.UPC) : 0)

  const [tags, setTags] = useState<string[]>([])
  const [openDate, setOpenDate] = useState(false)

  useEffect(() => {
    setProjectFields(props.project)
  }, [props.project])

  useEffect(() => {
    void getProjectTags()
      .then(setTags)
  }, [])

  useEffect(() => {
    moment.tz.setDefault('America/New_York')
  }, [])

  const [selectedPrimaryArtists, setSelectedPrimaryArtists] = useState<ArtistObject[]>(projectFields.primaryArtists)
  const [selectedFeaturedArtists, setSelectedFeaturedArtists] = useState<ArtistObject[]>(projectFields.featuredArtists)

  const handlePrimaryChange = (values: ArtistObject[] | undefined) => {
    setSelectedPrimaryArtists(values ?? [])
    const proj = Object.assign({}, projectFields)
    proj.primaryArtists = values ?? []
    setProjectFields(proj)
    void save({ ...proj, primaryArtists: values ?? [] })
  }

  const handleFeaturedChange = (values: ArtistObject[] | undefined) => {
    setSelectedFeaturedArtists(values ?? [])
    const proj = Object.assign({}, projectFields)
    proj.featuredArtists = values ?? []
    setProjectFields(proj)
    void save({ ...proj, featuredArtists: values ?? [] })
  }

  const checkTitleErrors = projectFields.title.toLowerCase().includes('feat.') ||
    projectFields.title.toLowerCase().includes('featuring') ||
    projectFields.title.toLowerCase().includes('ft.')

  const updateField = (field:
  'catalogNumber' | 'cLine' | 'description' | 'format' | 'genre' | 'pLine' | 'recordLabel' | 'title' | 'UPC',
  value: string) => {
    const proj = Object.assign({}, projectFields)
    proj[field] = value
    setProjectFields(proj)
    if (field === 'genre') {
      void save({ ...proj, genre: value })
    }
    if (field === 'format') {
      void save({ ...proj, format: value })
    }
  }

  const handleMultiFieldsChange = (values: string[],
    field: 'subGenres' | 'tags') => {
    const proj = Object.assign({}, projectFields)
    proj[field] = values
    setProjectFields(proj)
    if (field === 'subGenres') {
      void save({ ...proj, subGenres: values })
    } else {
      void save({ ...proj, tags: values })
    }
  }

  const updateStatus = (value: 'Confirmed' | 'Cut' | 'On Hold' | 'Released' | 'WIP') => {
    const proj = Object.assign({}, projectFields)
    proj.status = value
    setProjectFields(proj)
    void save({ ...proj, status: value })
  }

  const updateReleaseDate = (value: Date | null) => {
    if (value) {
      const dateItem = value.toISOString().slice(0, 10)
      const testItem = moment(dateItem, 'YYYY-MM-DD').tz('America/New_York')
      const proj = Object.assign({}, projectFields)
      proj.releaseDate = testItem.toDate()
      setProjectFields(proj)
      void save({ ...proj, releaseDate: testItem.toDate() })
    }
  }

  const save = async (newProject?: Project) => {
    await queue.add(async () => {
      const projectTemporary = newProject
        ? newProject
        : projectFields
      await savePartialProject({
        ...projectTemporary,
      })
        .then(item => {
          props.setProject(item)
        })
    })
  }

  const handleCloseToast = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
  }

  queue.on('active', () => {
    setSavedToastOpen(true)
  })

  queue.on('empty', () => {
    setSavedToastOpen(false)
  })

  useEffect(() => {
    const message = 'Your requests are not all processed, are you sure you want to leave?'
    function listener(event: BeforeUnloadEvent) {
      if (savedToast) {
        event.preventDefault()
        event.returnValue = message
        return message
      }
    }

    window.addEventListener('beforeunload', listener)

    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [savedToast])

  return (
    <>
      <Snackbar
        autoHideDuration={500}
        disableWindowBlurListener
        message='Saving project'
        onClose={handleCloseToast}
        open={savedToast}
      >
        <Alert
          icon={<CircularProgress
            sx={{
              width: '16px!important',
              height: '16px!important',
              color: 'white',
            }}
            variant='indeterminate'
          />}
          severity='success'
          sx={{ width: '100%' }}
          variant='filled'
        >
          Saving
        </Alert>
      </Snackbar>
      <Stack paddingBottom={4} spacing={4} width={1}>
        <Stack spacing={1} width={1}>
          <Grid
            container
            spacing={2}
            sx={{
              '& .MuiGrid-item': {
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
              },
            }}
            width={1}
          >
            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  lineHeight={1}
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Title *
                  <Tooltip
                    title='Please do not include artist names here, they are automatically pulled from recordings.'
                  >
                    <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                  </Tooltip>
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  error={props.distributing && !!props.project.title && props.project.title.length === 0}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  onBlur={() => void save()}
                  onChange={event_ => updateField('title', event_.target.value)}
                  placeholder='Title'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.title}
                />
                {props.distributing && (!props.project.title || props.project.title.length === 0) &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
              {checkTitleErrors &&
              <Typography color='error' variant='body2'>
                Do not include any versions or artist names in the title
              </Typography>}
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  lineHeight={1}
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Release Date *
                  <Tooltip
                    title='Distribute your release at least 7 business days in
                  advance to guarantee your release date.'
                  >
                    <InfoRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                  </Tooltip>
                </Typography>
                <Stack
                  onClick={() => setOpenDate(true)}
                  sx={{
                    cursor: 'pointer!important',
                  }}
                  width={1}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                    <DatePicker
                      disabled={props.project.distributed}
                      format='MMMM DD, YYYY'
                      onChange={date => updateReleaseDate(moment(date).toDate())}
                      onClose={() => setOpenDate(false)}
                      open={openDate}
                      sx={{
                        width: 1,
                        marginTop: 0,
                        cursor: 'pointer!important',
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          cursor: 'pointer!important',
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                        '.MuiInputBase-input': {
                          cursor: 'pointer!important',
                        },
                      }}
                      value={moment(projectFields.releaseDate.toISOString().slice(0, 10),
                        'YYYY-MM-DD').tz('America/New_York')}
                    />
                  </LocalizationProvider>
                </Stack>
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' overflow='hidden' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Primary artists
                </Typography>
                <SearchArtistComponentSubtle
                  disabled={props.project.distributed}
                  placeholder='Search Artists'
                  selectedArtists={selectedPrimaryArtists}
                  setSelectedArtists={handlePrimaryChange}
                />
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Featured Artists
                </Typography>
                <SearchArtistComponentSubtle
                  disabled={props.project.distributed}
                  placeholder='Search Artists'
                  selectedArtists={selectedFeaturedArtists}
                  setSelectedArtists={handleFeaturedChange}
                />
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Primary Genre *
                </Typography>
                <Autocomplete
                  disabled={props.project.distributed}
                  fullWidth
                  onChange={(event, value) => updateField('genre', value ?? '')}
                  options={GENRES}
                  renderInput={params =>
                    <TextField
                      {...params}
                      fullWidth
                      placeholder='Primary Genres'
                      sx={{
                        marginTop: 0,
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                      }}
                    />}
                  value={projectFields.genre}
                />
                {props.distributing && !props.project.genre &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Sub-Genres *
                </Typography>
                <Autocomplete
                  disabled={props.project.distributed}
                  fullWidth
                  multiple
                  onChange={(event, value) => handleMultiFieldsChange(value, 'subGenres')}
                  options={GENRES}
                  renderInput={params =>
                    <TextField
                      {...params}
                      fullWidth
                      placeholder='Sub-Genres'
                      sx={{
                        marginTop: 0,
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                      }}
                    />}
                  sx={{
                    '.MuiAutocomplete-tag': {
                      background: theme => theme.palette.background.input,
                    },
                  }}
                  value={projectFields.subGenres}
                />
                {props.distributing && props.project.subGenres.length === 0 &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  UPC
                  <Tooltip
                    title='The UPC code is used to identify an album. Leave this field empty
                  if you want Releese to generate one for you.'
                  >
                    <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                  </Tooltip>
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  error={!validUpcCode && projectFields.UPC.length > 0}
                  fullWidth
                  onBlur={() => void save()}
                  onChange={event_ => updateField('UPC', event_.target.value.replace(/\D/g, ''))}
                  placeholder='123456789123 or leave empty to autogenerate'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.UPC}
                />
                {((!upcValidation(Number(props.project.UPC)) ||
                  (props.project.UPC.includes('558451') && props.distributing)) &&
                  props.project.UPC && props.project.UPC.length > 0) &&
                  <Chip color='error' label='Invalid UPC' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  lineHeight={1}
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Record Label *
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  fullWidth
                  onBlur={() => void save()}
                  onChange={event_ => updateField('recordLabel', event_.target.value)}
                  placeholder='Record Records'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.recordLabel}
                />
                {props.distributing && !props.project.recordLabel &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  lineHeight={1}
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  C Line *
                  <Tooltip
                    title='The C Line is used to identify the copyright owner of an album, typically a publisher.'
                  >
                    <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                  </Tooltip>
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  fullWidth
                  onBlur={() => void save()}
                  onChange={event_ => updateField('cLine', event_.target.value)}
                  placeholder='Copyright Owner (Publisher)'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.cLine}
                />
                {props.distributing && !props.project.cLine &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  lineHeight={1}
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  P Line *
                  <Tooltip
                    title='The P Line is used to identify the product owner of an album, typically a record label.'
                  >
                    <HelpRoundedIcon color='action' sx={{ fontSize: 16, marginLeft: 0.5 }} />
                  </Tooltip>
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  fullWidth
                  onBlur={() => void save()}
                  onChange={event_ => updateField('pLine', event_.target.value)}
                  placeholder='Recording Owner (Label)'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.pLine}
                />
                {props.distributing && !props.project.pLine &&
                <Chip color='error' label='Missing' size='small' variant='filled' />}
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Catalog number
                </Typography>
                <TextField
                  autoComplete='new-password'
                  disabled={props.project.distributed}
                  fullWidth
                  onBlur={() => void save()}
                  onChange={event_ => updateField('catalogNumber', event_.target.value)}
                  placeholder='ABC001'
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.catalogNumber}
                />
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Format
                </Typography>
                <Autocomplete
                  fullWidth
                  onChange={(event, value) => updateField('format', value ?? '')}
                  options={RECORDING_VERSIONS}
                  renderInput={params =>
                    <TextField
                      {...params}
                      autoComplete='new-password'
                      fullWidth
                      placeholder='Format'
                      sx={{
                        marginTop: 0,
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                      }}
                    />}
                  value={projectFields.format}
                />
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Tags
                </Typography>
                <Autocomplete
                  freeSolo
                  fullWidth
                  multiple
                  onChange={(event, value) => handleMultiFieldsChange(value, 'tags')}
                  options={tags}
                  renderInput={params =>
                    <TextField
                      {...params}
                      autoComplete='new-password'
                      fullWidth
                      placeholder='Type and press enter to add a tag'
                      sx={{
                        marginTop: 0,
                        background: 'transparent!important',
                        border: 'none',
                        '.MuiInputBase-root': {
                          background: 'transparent!important',
                          borderColor: 'transparent!important',
                          paddingY: 0,
                          marginTop: 0,
                        },
                      }}
                    />}
                  value={projectFields.tags}
                />
              </Stack>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xl={6} xs={12}>
              <Stack alignItems='center' direction='row' paddingBottom={2} spacing={1} width={1}>
                <Typography
                  sx={{
                    marginTop: 0,
                    width: 100,
                    flexShrink: 0,
                  }}
                  variant='inputLabel'
                >
                  Status
                </Typography>
                <SelectField
                  fullWidth
                  onChange={updateStatus}
                  options={[
                    { value: PROJECT_STATUS[0], label: PROJECT_STATUS[0].toString() },
                    { value: PROJECT_STATUS[1], label: PROJECT_STATUS[1].toString() },
                    { value: PROJECT_STATUS[2], label: PROJECT_STATUS[2].toString() },
                    { value: PROJECT_STATUS[3], label: PROJECT_STATUS[3].toString() },
                    { value: PROJECT_STATUS[4], label: PROJECT_STATUS[4].toString() },
                  ]}
                  renderValue={item => <Chip color={setColor(item)} label={item} variant='subtle' />}
                  sx={{
                    marginTop: 0,
                    background: 'transparent!important',
                    border: 'none',
                    '.MuiInputBase-root': {
                      background: 'transparent!important',
                      borderColor: 'transparent!important',
                      paddingY: 0,
                      marginTop: 0,
                    },
                  }}
                  value={projectFields.status}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  )
}

export default EditProjectDetailsComponent
