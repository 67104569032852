import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material'

type Props = {
  featureGroup: {
    title: string
    featureItem: {
      title: string
      free: string
      creator: string
      creatorPro: string
      enterprise: string
      enterprisePlus?: string | undefined
      freeIcon: boolean
      creatorIcon: boolean
      creatorProIcon: boolean
      enterpriseIcon: boolean
      enterprisePlusIcon: boolean
    }[]
  }[]
}

const iconSize = 20

const CompleteFeatureList = (props: Props) => {
  const currentTheme = useTheme().palette.mode

  return (
    <Stack
      alignItems='center'
      direction='column'
      maxWidth='1142px'
      paddingX={2}
      paddingY={6}
      sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'none', xs: 'none' } }}
      width={1}
    >
      <Typography
        textAlign='center'
        variant='h2'
      >
        Complete features list
      </Typography>
      <Stack sx={{ width: 1 }}>
        {props.featureGroup.map(featureGroupItem =>
          <Stack key={featureGroupItem.title} marginTop={4} spacing={1} width={1}>
            <Table
              aria-label='pricing table'
              size='small'
            >
              <TableHead sx={{
                '.MuiTableCell-root': {
                  borderBottom: 2,
                  borderBottomColor: 'divider',
                  color: 'text.secondary',
                },
              }}
              >

                <TableRow>
                  <TableCell align='center' width='25%' />
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h4'
                    >
                      Free
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h4'
                    >
                      Starter
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h4'
                    >
                      Essential
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h4'
                    >
                      Pro
                    </Typography>
                  </TableCell>
                  <TableCell align='center' width='15%'>
                    <Typography
                      color='text.primary'
                      variant='h4'
                    >
                      Business
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {featureGroupItem.featureItem.map(featureItemRow =>
                  <TableRow
                    key={featureItemRow.title}
                    sx={{
                      '.MuiTableCell-root': {
                        paddingY: 2,
                        borderBottom: 0.5,
                        borderBottomColor: 'divider',
                        color: 'text.secondary',
                      },
                    }}
                  >
                    <TableCell align='left'>
                      <Typography variant='body2'>
                        {featureItemRow.title}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.free.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.free}
                      </Typography>}
                      {featureItemRow.freeIcon
                        ? <CheckCircleRoundedIcon color='action' sx={{ width: iconSize, height: iconSize }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.creator.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.creator}
                      </Typography>}
                      {featureItemRow.creatorIcon
                        ? <CheckCircleRoundedIcon
                          color='primary'
                          sx={{ width: iconSize, height: iconSize }}
                        />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.creatorPro.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.creatorPro}
                      </Typography>}
                      {featureItemRow.creatorProIcon
                        ? <CheckCircleRoundedIcon sx={{ width: iconSize, height: iconSize, color: '#327659' }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.enterprise.length > 0 &&
                      <Typography variant='body2'>
                        {featureItemRow.enterprise}
                      </Typography>}
                      {featureItemRow.enterpriseIcon
                        ? <CheckCircleRoundedIcon sx={{ width: iconSize, height: iconSize, color: '#d9bc66' }} />
                        : null}
                    </TableCell>
                    <TableCell align='center'>
                      {featureItemRow.enterprisePlus && featureItemRow.enterprisePlus.length > 0
                        ? <Typography variant='body2'>
                          {featureItemRow.enterprisePlus}
                        </Typography>
                        : null}
                      {featureItemRow.enterprisePlusIcon
                        ? <CheckCircleRoundedIcon
                          sx={{
                            width: iconSize,
                            height: iconSize,
                            color: currentTheme === 'light'
                              ? 'black'
                              : 'white' }}
                        />
                        : null}
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </Stack>)}
      </Stack>
    </Stack>
  )
}

export default CompleteFeatureList
